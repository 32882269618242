import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { IServiceCenterInfoState, IServiceCenterValues } from '../app/types'

const centerName = localStorage.getItem('centerName')
const centerAddress = localStorage.getItem('centerAddress')

const initialState: IServiceCenterInfoState = {
  Name: centerName ? centerName.toString() : null,
  Address: centerAddress ? centerAddress.toString() : null,
}

export const serviceCenterInfoSlicer = createSlice({
  name: 'serviceCenterInfo',
  initialState,
  reducers: {
    setServiceCenterInfo: (state, action: PayloadAction<IServiceCenterValues>) => {
      state.Name = action.payload.Name
      state.Address = action.payload.Address
    },
  },
})

export const { setServiceCenterInfo } = serviceCenterInfoSlicer.actions

export default serviceCenterInfoSlicer.reducer
