import styled from '@emotion/styled'
import { Box, Link } from '@mui/material'
import { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import { Trans } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { postInvitationInformation } from '../../../api/postInvitationInformation'
import { CONTACT_NUMBER } from '../../../app/constants'
import { InvalidInvitationIdException, PatientNotFoundException } from '../../../app/exceptions'
import { IFormValues } from '../../../app/types'
import AppBar from '../../../components/AppBar'
import Dialog from '../../../components/Dialog'
import NextButton from '../../../components/NextButton'
import PatientBasicInfoForm from '../../../components/PatientBasicInfoForm'
import ViewContainer from '../../../components/ViewContainer'
import useDialog from '../../../hooks/dialog'
import usePathTranslation from '../../../hooks/pathTranslation'
import { setInvitationPatient } from '../../../store/invitation'
import useFormMutation from './useInvitationForm'

const PatientForm = () => {
  const { t } = usePathTranslation()
  const alertHook = useDialog()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [invitationId, setInvitationId] = useState<string>('')
  const {
    control, errors, handleSubmit,
  } = useFormMutation()
  const [loading, setLoading] = useState(false)
  const { state } = useLocation()

  useEffect(() => {
    if (state) {
      setInvitationId(state.invitationId)
    }
  }, [state])

  const handleInvitationInfoSubmit = async (data: IFormValues) => {
    setLoading(true)
    postInvitationInformation(data, invitationId!)
      .then((r: any) => {
        dispatch(setInvitationPatient({
          patient: {
            firstName: data.patientFirstName,
            lastName: data.patientLastName,
            dateOfBirth: data.dateOfBirth,
            email: r.email,
            language: r.language,
          },
          appointmentsSpecification: {
            dsiCutOffDate: r.dsi_cut_off_date,
          },
          clinic: {
            id: r.clinic.id,
            address: r.clinic.address,
            geolocation: { lat: r.clinic.coords.lat, lng: r.clinic.coords.lng },
          },
          invitationId,
        }))
        navigate('../../dsi-fit/dsi-appointment', { relative: 'path' })
      }).catch((error: AxiosError) => {
        if (error instanceof InvalidInvitationIdException) {
          alertHook.setDialog({
            title: t('invitation_patient_form_view.dialog.bad_request.title'),
            description: <Trans
              components={[
                <Link
                  href={`tel:${CONTACT_NUMBER.value}`}
                  style={{ whiteSpace: 'nowrap' }}
                />,
              ]}
              i18nKey="invitation_patient_form_view.dialog.bad_request.description"
              t={t}
              values={{
                clinicNumber: CONTACT_NUMBER.description,
              }}
            />,
          })
        } else if (error instanceof PatientNotFoundException) {
          alertHook.setDialog({
            title: t('invitation_patient_form_view.dialog.not_found.title'),
            description: <Trans
              components={[
                <Link
                  href={`tel:${CONTACT_NUMBER.value}`}
                  style={{ whiteSpace: 'nowrap' }}
                />,
              ]}
              i18nKey="invitation_patient_form_view.dialog.not_found.description"
              t={t}
              values={{
                clinicNumber: CONTACT_NUMBER.description,
              }}
            />,
          })
        } else {
          alertHook.setDialog({
            title: 'Internal Server Error',
            description: 'An error occurred while processing your request',
          })
        }
      }).finally(() => {
        setLoading(false)
      })
  }

  return (
    <ViewContainer
      clinicNumberInFooter
      footerDivider
    >
      <AppBar
        description={(
          <div>
            {t('invitation_form_view.subtitle')}
          </div>
)}
        maxProgress={5}
        progress={1}
        title={t('invitation_form_view.title')}
      />
      <StyledBox
        component="form"
        onSubmit={handleSubmit(handleInvitationInfoSubmit)}
      >
        <PatientBasicInfoForm
          control={control}
          errors={errors}
          required
        />
        <NextButton
          isLoading={loading}
          secondary
          text={t('invitation_form_view.button')}
        />

      </StyledBox>
      <Dialog dialogHook={alertHook} />
    </ViewContainer>
  )
}

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
`

export default PatientForm
