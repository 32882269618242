import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { IAppointmentInfoState, IAppointmentValues } from '../app/types'

const appointmentInfo = localStorage.getItem('appointmentInfo')
const initialState: IAppointmentInfoState = appointmentInfo
  ? JSON.parse(appointmentInfo) : {}

export const appointmentInfoSlicer = createSlice({
  name: 'appointmentInfo',
  initialState,
  reducers: {
    setServiceCenterID: (state, action: PayloadAction<number>) => {
      state.ServiceCenterID = action.payload
    },

    setAppointmentInfo: (state, action: PayloadAction<IAppointmentValues>) => {
      state.ServiceCenterID = action.payload.ServiceCenterID
      state.AppointmentDateTime = action.payload.AppointmentDateTime
    },
  },
})

export const { setServiceCenterID, setAppointmentInfo } = appointmentInfoSlicer.actions

export default appointmentInfoSlicer.reducer
