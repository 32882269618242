import { Box, Grid } from '@mui/material'
import styled from '@emotion/styled'
import { Link, useLocation, useParams } from 'react-router-dom'
import logo from '../assets/images/logo-color.svg'
import {
  INFO_COLOR, MAX_MOBILE_DIMENSIONS, SUPPORTED_LANGUAGES, MAX_TABLET_DIMENSIONS,
} from '../app/constants'
import usePathTranslation from '../hooks/pathTranslation'

const MainHeader = () => (
  <Grid item>
    <Box
      alt="logo"
      component="img"
      src={logo}
      width="100%"
    />
  </Grid>
)

const TranslationHeader = () => {
  const { changeLanguage, language, t } = usePathTranslation()

  return (
    <SpaceBetweenGrid>
      <Grid item>
        <Box
          alt="logo"
          component="img"
          src={logo}
        />
      </Grid>
      <Grid
        alignItems="center"
        direction="row"
        display="flex"
        item
        justifyContent="flex-end"
      >
        <span style={{ color: 'gray' }}>
          {t('language')}
          :
        </span>
        <StyledHorizontalList>
          {Object.keys(SUPPORTED_LANGUAGES).map((lang, idx) => (
            <>
              <li key={lang}>
                <button
                  onClick={() => changeLanguage(lang)}
                  style={{
                    color: 'gray',
                    fontWeight: language === lang ? 'bold' : 'normal',
                    cursor: 'pointer',
                    border: 'none',
                    background: 'none',
                    padding: 0,
                  }}
                  type="button"
                >
                  {SUPPORTED_LANGUAGES[lang as keyof typeof SUPPORTED_LANGUAGES]}
                </button>
              </li>
              {idx < Object.keys(SUPPORTED_LANGUAGES).length - 1 && (
                <span style={{ color: 'gray' }}>|</span>
              )}
            </>
          ))}
        </StyledHorizontalList>
      </Grid>
    </SpaceBetweenGrid>

  )
}

const HeaderWithChangeAppointmentLink = () => (
  <HeaderWrapper>
    <Grid item />
    <Grid item>
      <CenteredBox>
        <Box
          alt="logo"
          component="img"
          src={logo}
          width="100%"
        />
      </CenteredBox>
    </Grid>
    <FlexEndGrid item>
      <ChangeAppointmentLink
        to="/reschedule"
      >
        I need to change an existing appointment
      </ChangeAppointmentLink>
    </FlexEndGrid>
  </HeaderWrapper>
)

interface HeaderProps {
  includeLanguageSelector?: boolean
}
const Header = ({ includeLanguageSelector }: HeaderProps) => {
  const location = useLocation()
  const { lang } = useParams()

  const isRoot = location.pathname === `/${lang ?? ''}`

  return (
    <StyledHeader>
      <Grid
        container
        justifyContent="center"
      >
        {(!isRoot && includeLanguageSelector) && (
          <TranslationHeader />
        )}
        {isRoot && !includeLanguageSelector && (
          <HeaderWithChangeAppointmentLink />
        )}
        {!isRoot && !includeLanguageSelector && (
          <MainHeader />
        )}
      </Grid>
    </StyledHeader>
  )
}

const HeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1rem;
  column-gap: 1rem;

  @media (min-width: ${MAX_MOBILE_DIMENSIONS}px) {
    width: 100%;
    grid-template-columns: 1fr 10rem 1fr;
    grid-column;-gap: 2rem
  }
`

const FlexEndGrid = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media (min-width: ${MAX_MOBILE_DIMENSIONS}px) {    
    justify-content: flex-end;
    text-align: end;
  }
`

const ChangeAppointmentLink = styled(Link)`
  color: ${INFO_COLOR}
`

const CenteredBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledHorizontalList = styled.ul`
  display: flex;
  gap: 10px;
  list-style: none;
  padding-left: 10px;
`

const SpaceBetweenGrid = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50%;

  @media (max-width: ${MAX_TABLET_DIMENSIONS}px) {
    width: 100%;
  }

  @media (max-width: ${MAX_MOBILE_DIMENSIONS}px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

const StyledHeader = styled.header`
  background: #fff;
  box-shadow: 0 2px 4px rgb(0 0 0 / 25%);
  padding: 15px;

  @media (min-width: ${MAX_MOBILE_DIMENSIONS}px) {
    padding: 30px;
  }
`

export default Header
