import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import AppBar from '../../../components/AppBar'
import ViewContainer from '../../../components/ViewContainer'
import useClinicAppointmentSelector from '../../../hooks/clinicAppointmentSelector'
import NextButton from '../../../components/NextButton'
import BackButton from '../../../components/BackButton'
import AppointmentDescription from '../../../components/AppointmentDescription'
import AppointmentManager from '../../../components/AppointmentManager'
import ScheduledAppointmentInfo from '../../../components/ScheduledAppointmentInfo'
import { setFitAppointment } from '../../../store/invitation'
import usePathTranslation from '../../../hooks/pathTranslation'
import { BLUE_CRANIAL_COLOR, GREEN_CRANIAL_COLOR, LIGHT_GREEN_CRANIAL_COLOR } from '../../../app/constants'

const ScheduleFITAppointment = () => {
  const { t } = usePathTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const invitation = useSelector((state: any) => state.invitation)
  const clinicAppointmentSelector = useClinicAppointmentSelector('Fit', invitation, [null, null])

  return (
    <ViewContainer
      clinicNumberInFooter
      footerDivider
    >
      <AppBar
        buttonBackAction={() => {
          navigate('../dsi-appointment', { relative: 'path' })
        }}
        description={(
          <AppointmentDescription>
            {t('fit_appointment_view.selected_dsi_label')}
          </AppointmentDescription>
        )}
        maxProgress={5}
        progress={3}
        title={t('fit_appointment_view.title')}
      />

      <ScheduledAppointmentInfo
        address={clinicAppointmentSelector.clinicLocation.address}
        borderColor={BLUE_CRANIAL_COLOR}
        date={dayjs(invitation.dsiAppointment)}
        duration={t('dsi_fit_almost_done_view.dsi_appointment.duration', {
          duration: '20',
        })}
        title={t('dsi_fit_almost_done_view.dsi_appointment.title')}
      />

      <br />

      <AppointmentDescription>
        {t('fit_appointment_view.subtitle', {
          minDuration: '50',
          maxDuration: '60',
        })}
      </AppointmentDescription>

      <br />

      <AppointmentManager
        appointmentDetails={{
          availableDateDescription: t('appointment_manager.available_dates.dsi.subtitle'),
        }}
        appointmentSelector={clinicAppointmentSelector}
        appointmentStyle={{
          backgroundColor: LIGHT_GREEN_CRANIAL_COLOR,
          selectedBackgroundColor: GREEN_CRANIAL_COLOR,
          textColor: GREEN_CRANIAL_COLOR,
          selectedTextColor: 'white',
        }}
      />
      <VerticalLayout>
        <NextButton
          isDisabled={!clinicAppointmentSelector.isNextButtonEnabled}
          onClick={() => {
            dispatch(setFitAppointment(`${clinicAppointmentSelector.selectedDate} ${clinicAppointmentSelector.selectedTime}`))
            navigate('../confirm-appointments', { relative: 'path' })
          }}
          secondary
          text={t('fit_appointment_view.button')}
        />
        <BackButton onClick={() => {
          navigate('../dsi-appointment', { relative: 'path' })
        }}
        />
      </VerticalLayout>
    </ViewContainer>
  )
}

const VerticalLayout = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  width: 100%;
`

export default ScheduleFITAppointment
