import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { IFormInfoState, IFormValues } from '../app/types'
import {
  RELATION_LOOKUP,
  GENDER_LOOKUP,
  COUNTRY_LOOKUP,
  LANGUAGE_DICT,
} from '../app/constants'

const personDetails = localStorage.getItem('personDetails')
const guardianDetails = localStorage.getItem('guardianDetails')

const initialState: IFormInfoState = {
  PersonDetails: personDetails ? JSON.parse(personDetails) : undefined,
  Guardian: guardianDetails ? JSON.parse(guardianDetails) : undefined,
}

export const formInfoSlicer = createSlice({
  name: 'formInfo',
  initialState,
  reducers: {
    setFormInfo: (state, action: PayloadAction<IFormValues>) => {
      const { payload } = action

      state.PersonDetails = {
        Name: {
          First: payload.patientFirstName,
          Last: payload.patientLastName,
        },
        DOB: payload.dateOfBirth as string,
        Email: payload.email,
        Message: payload.message,
        Gender: payload.gender ? GENDER_LOOKUP[payload.gender] : undefined,
        Language: payload.language ? {
          LanguageCode: payload.language,
          LanguageDescription: LANGUAGE_DICT[payload.language],
        } : undefined,
        Address: {
          Line1:
            payload.firstLine?.replace(/\s+/, '') !== ''
              ? payload.firstLine
              : undefined,
          Line2:
            payload.secondLine?.replace(/\s+/, '') !== ''
              ? payload.secondLine
              : undefined,
          City: payload.city || undefined,
          State: payload.stateCode,
          Zip:
            payload.zipCode?.replace(/\s+/, '') !== ''
              ? payload.zipCode
              : undefined,
          Country: COUNTRY_LOOKUP.US,
        },
        Phone: {
          Code: payload.countryCode,
          Number: payload.phoneNumber,
        },
      }
      state.Guardian = {
        Guardian1FirstName: payload.guardianFirstName,
        Guardian1LastName: payload.guardianLastName,
        Guardian1Relation: payload.guardianRelation
          ? RELATION_LOOKUP[payload.guardianRelation]
          : undefined,
        Guardian1Phone: {
          GuardianCode: payload.guardianPhoneCode,
          GuardianNumber: payload.guardianPhoneNumber,
        },
        Guardian1Email: payload.guardianEmail,
        Guardian2FirstName: payload.guardian2FirstName,
        Guardian2LastName: payload.guardian2LastName,
        Guardian2Relation: payload.guardian2Relation
          ? RELATION_LOOKUP[payload.guardian2Relation]
          : undefined,
        Guardian2Phone: {
          GuardianCode: payload.guardian2PhoneCode,
          GuardianNumber: payload.guardian2PhoneNumber,
        },
        Guardian2Email: payload.guardian2Email,
      }
    },
  },
})

export const { setFormInfo } = formInfoSlicer.actions

export default formInfoSlicer.reducer
