import axios from 'axios'
import dayjs from 'dayjs'
import { DSIFIT_ENDPOINT } from '../app/constants'
import { IFormValues } from '../app/types'
import { InvalidInvitationIdException, PatientNotFoundException } from '../app/exceptions'

export async function postInvitationInformation(
  patientInformation: IFormValues,
  invitationId: string,
) {
  const firstName = patientInformation.patientFirstName
  const lastName = patientInformation.patientLastName
  const birthDate = dayjs(patientInformation.dateOfBirth)
  const url = `${DSIFIT_ENDPOINT}/api/resolve-invitation/${invitationId}`

  const [status, message] = await axios.post(url, {
    patient_information: {
      first_name: firstName,
      last_name: lastName,
      date_of_birth: birthDate.format('YYYY-MM-DD'),
    },
  }).then((r) => [r.status, r.data]).catch((error) => {
    if (error.response) {
      return [error.response.status, error.response.data]
    }
    return [500, '']
  })

  if (status === 400) {
    throw new InvalidInvitationIdException(message)
  } else if (status === 404) {
    throw new PatientNotFoundException(message)
  } else if (status === 500) {
    throw new Error('Internal server error')
  }

  return message
}

export async function validateInvitationId(invitationId: string) {
  const url = `${DSIFIT_ENDPOINT}/api/validate-invitation/${invitationId}`
  await axios.post(url)
}
