import styled from '@emotion/styled'
import { Box, Typography } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import { DISPLAY_FONT_FAMILY } from '../app/constants'
import { CalendarIcon, ClockIcon, LocationIcon } from '../assets/icons'
import 'dayjs/locale/en'
import 'dayjs/locale/es'
import usePathTranslation from '../hooks/pathTranslation'

dayjs.extend(advancedFormat)

interface ScheduledAppointmentInfoProps {
  title: string
  address: string
  date: Dayjs
  duration: string
  borderColor: string
}

const ScheduledAppointmentInfo = ({
  address, date, duration, borderColor, title,
}: ScheduledAppointmentInfoProps) => {
  const { t, language } = usePathTranslation()
  const dateFormat = t('scheduled_appointment_info.datetime_format')

  return (
    <StyledVerticalBox
      component="div"
      sx={{
        borderLeft: `10px solid ${borderColor}`,
      }}
    >
      <Typography
        sx={{
          margin: '4px 0',
          fontWeight: 600,
          fontSize: '18px',
          fontFamily: DISPLAY_FONT_FAMILY,
        }}
        variant="h5"
      >
        {title}
      </Typography>
      <HorizontalLayout>
        <LocationIcon />
        {' '}
        <span style={{ textAlign: 'left' }}>{address}</span>
      </HorizontalLayout>
      <HorizontalLayout>
        <CalendarIcon />
        {' '}
        <span style={{ textAlign: 'left' }}>{date.locale(language).format(dateFormat)}</span>
      </HorizontalLayout>
      <HorizontalLayout>
        <ClockIcon />
        {duration}
      </HorizontalLayout>
    </StyledVerticalBox>

  )
}

const StyledVerticalBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  gap: 6px;

  box-shadow: 0px 2px 10px #aaa;
  border-radius: 8px;
  padding: 0.5rem 1.2rem;
`

const HorizontalLayout = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`

export default ScheduledAppointmentInfo
