import { TFunction } from 'i18next'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { SUPPORTED_LANGUAGES } from '../app/constants'

const usePathTranslation = () => {
  const { t } = useTranslation()
  const { lang } = useParams()
  const location = useLocation()
  const navigate = useNavigate()

  const language = lang ?? 'en'

  useEffect(() => {
    if (!Object.keys(SUPPORTED_LANGUAGES).includes(language)) {
      changeLanguage('en')
    }
  }, [language])

  function changeLanguage(_language: string) {
    const newPathName = lang == null
      ? `/${_language}${location.pathname}`
      : location.pathname.replace(`/${lang}/`, `/${_language}/`)

    navigate(newPathName)
  }

  // @ts-ignore
  const tFixed: TFunction<'translation', undefined> = (key, options) => t(key, { ...options, lng: language })

  return { t: tFixed, language, changeLanguage }
}

export default usePathTranslation
