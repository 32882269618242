import {
  Box, Typography, CircularProgress, Tabs, Tab,
} from '@mui/material'
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api'
import { DateCalendar, DigitalClock, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'
import styled from '@emotion/styled'
import { useState } from 'react'
import {
  BLUE_CRANIAL_COLOR,
  DATE_PATTERNS, DISPLAY_FONT_FAMILY, MAX_MOBILE_DIMENSIONS,
  MAX_TABLET_DIMENSIONS,
} from '../app/constants'
import { AppointmentSelectorProps } from '../hooks/clinicAppointmentSelector'
import usePathTranslation from '../hooks/pathTranslation'
import mapStyle from './mapStyle.json'

interface AppointmentManagerProps {
  appointmentSelector: AppointmentSelectorProps
  appointmentStyle: AppointmentStylesProps
  appointmentDetails: AppointmentDetailsProps
}
const AppointmentManager = ({
  appointmentSelector,
  appointmentStyle,
  appointmentDetails,
}: AppointmentManagerProps) => (
  <>
    <ClinicComponent clinic={appointmentSelector.clinicLocation} />
    <AppointmentPicker
      appointmentSelector={appointmentSelector}
      appointmentStyle={appointmentStyle}
      text={appointmentDetails.availableDateDescription}
    />
  </>
)

interface ClinicComponentProps {
  clinic: AppointmentSelectorProps['clinicLocation']
}

const ClinicComponent = ({ clinic }: ClinicComponentProps) => {
  const { t } = usePathTranslation()
  return (
    <VerticalLayout style={{ alignItems: 'start' }}>
      <Typography
        sx={{
          fontFamily: DISPLAY_FONT_FAMILY,
          fontWeight: 600,
          padding: '0px 0',
        }}
        variant="subtitle1"
      >
        {t('appointment_manager.clinic_location.label')}
      </Typography>
      <span style={{ textAlign: 'left' }}>{clinic.address}</span>

      <Map clinicGeolocation={clinic.geolocation} />
    </VerticalLayout>
  )
}

interface MapProps {
  clinicGeolocation: {
    lat: number
    lng: number
  }

}
const Map = ({ clinicGeolocation }: MapProps) => {
  const { language } = usePathTranslation()
  const [isLoaded, setIsLoaded] = useState(false)
  const containerStyle = {
    width: '100%',
    height: '400px',
    marginTop: '1rem',
  }
  const zoom = 14

  return (
    <LoadScript
      key={`map-${language}`}
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY || ''}
      language={language}
      onLoad={() => setIsLoaded(true)}
    >
      {isLoaded ? (
        <GoogleMap
          center={clinicGeolocation}
          mapContainerStyle={containerStyle}
          options={{
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
            zoomControl: false,
            disableDefaultUI: true,
            disableDoubleClickZoom: true,
            styles: mapStyle,
          }}
          zoom={zoom}
        >
          <Marker
            icon={{
              fillColor: BLUE_CRANIAL_COLOR,
              path: google.maps.SymbolPath.CIRCLE,
              scale: 11,
              strokeColor: 'white',
              fillOpacity: 1,
              strokeWeight: 1,
              strokeOpacity: 0.5,
            }}
            position={clinicGeolocation}
          />
        </GoogleMap>
      ) : null}
    </LoadScript>
  )
}

interface AppointmentPickerProps {
  appointmentSelector: AppointmentSelectorProps
  appointmentStyle: AppointmentStylesProps
  text: string
}
const AppointmentPicker = ({
  appointmentSelector, appointmentStyle, text,
}: AppointmentPickerProps) => {
  const { t } = usePathTranslation()
  return (
    <StyledBox
      component="form"
      style={{ alignItems: 'start' }}
    >
      <Typography
        sx={{
          fontFamily: DISPLAY_FONT_FAMILY,
          fontWeight: 600,
          padding: '0px 0',
        }}
        variant="subtitle1"
      >
        {t('appointment_manager.available_dates.title')}
      </Typography>
      <Typography
        sx={{
          fontFamily: DISPLAY_FONT_FAMILY,
          textAlign: 'left',
        }}
        variant="body1"
      >
        {text}
      </Typography>

      <br />

      <DateTimeWrapper>
        <DatePickerWrapper>
          <DatePicker
            availableDates={appointmentSelector.availableDates}
            handleDateChange={appointmentSelector.handleDateChange}
            selectedDate={appointmentSelector.selectedDate}
            style={appointmentStyle}
          />
        </DatePickerWrapper>

        <TimePickerWrapper>
          <TimePicker
            availableTimes={appointmentSelector.availableTimes}
            handleTimeChange={appointmentSelector.handleTimeChange}
            loading={appointmentSelector.loading}
            selectedTime={appointmentSelector.selectedTime}
            style={appointmentStyle}
          />
        </TimePickerWrapper>

      </DateTimeWrapper>
    </StyledBox>
  )
}

interface DatePickerProps {
  availableDates: string[]
  handleDateChange: (date: string) => void
  selectedDate: string | null
  style: AppointmentStylesProps
}
const DatePicker = ({
  availableDates, handleDateChange, selectedDate, style,
}: DatePickerProps) => {
  const { language } = usePathTranslation()
  const minDate = dayjs(availableDates[0] || undefined, { format: DATE_PATTERNS.greenway_date })
  const maxDate = dayjs(
    availableDates[availableDates.length - 1] || undefined,
    { format: DATE_PATTERNS.greenway_date },
  )

  return (
    <LocalizationProvider
      adapterLocale={language}
      dateAdapter={AdapterDayjs}
    >
      <DateCalendar
        disableHighlightToday
        maxDate={maxDate}
        minDate={minDate}
        onChange={(date) => handleDateChange((date as Dayjs).format(DATE_PATTERNS.greenway_date))}
        shouldDisableDate={(date) => (!availableDates.includes((
          date as Dayjs).format(DATE_PATTERNS.greenway_date)))}
        slotProps={{
          day: {
            sx: {
              color: style.textColor,
              backgroundColor: style.backgroundColor,
              '&.Mui-selected': {
                backgroundColor: style.selectedBackgroundColor,
                color: style.selectedTextColor,
                ':hover': {
                  backgroundColor: style.backgroundColor,
                  color: style.selectedTextColor,
                },
                ':focus': {
                  backgroundColor: style.selectedBackgroundColor,
                  color: style.selectedTextColor,
                },
              },
              '&.Mui-disabled': {
                color: 'lightgrey',
                backgroundColor: 'white',
              },
              ':hover': {
                backgroundColor: style.selectedBackgroundColor,
                color: style.selectedTextColor,
              },
            },
          },
        }}
        sx={{
          border: '1px solid lightgrey',
        }}
        value={selectedDate ? dayjs(selectedDate) : null}
      />
    </LocalizationProvider>
  )
}

interface TimePickerProps {
  availableTimes: string[] | null
  handleTimeChange: (time: string) => void
  loading: boolean
  selectedTime?: string | null
  style: AppointmentStylesProps
}

const TimePicker = ({
  availableTimes, handleTimeChange, loading, selectedTime, style,
}: TimePickerProps) => {
  const { t } = usePathTranslation()
  const amAvailableTimes = availableTimes?.filter((time) => dayjs(time, 'HH:mm').hour() < 12)
  const pmAvailableTimes = availableTimes?.filter((time) => dayjs(time, 'HH:mm').hour() >= 12)
  const [tabIndex, setTabIndex] = useState(amAvailableTimes?.length === 0 ? 1 : 0)
  const currentAvailableTimes = tabIndex === 0 ? amAvailableTimes : pmAvailableTimes

  if (loading) {
    return (
      <div style={{
        width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center',
      }}
      >
        <CircularProgress size={20} />
      </div>
    )
  }
  if (availableTimes == null) {
    return (
      <div style={{
        width: '200px',
        height: '100%',
        paddingInline: '1rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      >
        <Typography
          sx={{
            fontFamily: DISPLAY_FONT_FAMILY,
            textAlign: 'center',
            color: 'lightgrey',
          }}
          variant="body2"
        >
          {t('appointment_manager.time_picker.no_selected.label')}
        </Typography>
      </div>
    )
  }

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
    >
      <Box
        alignItems="stretch"
        display="flex"
        flexDirection="column"
        maxHeight="100%"
      >
        <Tabs
          onChange={(_, value) => {
            setTabIndex(value)
          }}
          style={{
            display: 'inline', margin: 'auto', paddingBottom: '5px', minHeight: '35px', marginBlock: 0,
          }}
        >
          <Tab
            disabled={amAvailableTimes!.length === 0}
            label="AM"
            style={{
              backgroundColor: tabIndex === 0
                ? '#eee' : 'inherit',
              borderRadius: '10px',
              height: '30px',
              minHeight: 0,
            }}
          />
          <Tab
            disabled={pmAvailableTimes!.length === 0}
            label="PM"
            style={{
              backgroundColor: tabIndex === 1
                ? '#eee' : 'inherit',
              borderRadius: '10px',
              height: '30px',
              minHeight: 0,
            }}
          />
        </Tabs>
        <DigitalClock
          onChange={(time) => handleTimeChange((time as Dayjs).format('HH:mm:ss'))}
          shouldDisableTime={
          (time) => !currentAvailableTimes!.includes((time as Dayjs).format('HH:mm:ss'))
        }
          skipDisabled
          slotProps={{
            digitalClockItem: {
              sx: {
                color: style.textColor,
                margin: '0.5rem 0',
                width: '11rem',
                textAlign: 'center',
                borderRadius: '20px',
                backgroundColor: style.backgroundColor,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '35px',
                minHeight: 0,

                '&.Mui-selected': {
                  backgroundColor: style.selectedBackgroundColor,
                  color: style.selectedTextColor,
                  ':hover': {
                    backgroundColor: style.selectedBackgroundColor,
                    color: style.selectedTextColor,
                  },
                },
                '&.Mui-disabled': {
                  color: 'lightgrey',
                },
                ':hover': {
                  backgroundColor: style.selectedBackgroundColor,
                  color: style.selectedTextColor,
                },
              },
            },
          }}
          sx={{
            width: '100%',
            maxHeight: '336px',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
          }}
          timeStep={10}
          value={selectedTime ? dayjs(selectedTime, 'HH:mm') : null}
        />
      </Box>

    </LocalizationProvider>
  )
}

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 2rem;
  width: 100%;
`

const VerticalLayout = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0rem;
  width: 100%;
`

const DateTimeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  items-align: start;
  min-width: 100%;
  max-width: 336px;
  gap: 20px;

  @media (max-width: 1200px) {
    flex-direction: column;
    justify-content: start;
    items-align: center;
  }

  @media (max-width: ${MAX_TABLET_DIMENSIONS}px) {
    flex-direction: row;
    justify-content: space-evenly;
    items-align: start;
  }

  @media (max-width: ${MAX_MOBILE_DIMENSIONS}px) {
    flex-direction: column;
    justify-content: start;
    items-align: center;
    gap: 20px;
  }
`

const DatePickerWrapper = styled.div`
  width: 100%,
  display: flex,
  justifyContent: flex-end,

  @media (max-width: ${MAX_TABLET_DIMENSIONS}px) {
    width: 100%;
    justify-content: center;
  }
`

const TimePickerWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: start;
  max-height: 336px;

  @media (max-width: 1200px) {
    justify-content: center;
  }

  @media (max-width: ${MAX_TABLET_DIMENSIONS}px) {
    justify-content: start;
  }

  @media (max-width: ${MAX_MOBILE_DIMENSIONS}px) {
    justify-content: center;
  }
`

type AppointmentStylesProps = {
  backgroundColor: string
  selectedBackgroundColor: string
  textColor: string
  selectedTextColor: string
}

type AppointmentDetailsProps = {
  availableDateDescription: string
}

export default AppointmentManager
