import { FormLabel, Typography } from '@mui/material'
import styled from '@emotion/styled'
import { IFormLabelProps } from '../app/types'

const StyledFormLabel = styled(FormLabel)`
  align-items: center;
  display: flex;
  font-weight: 600;
  padding-top: 15px;
`

const FormLabelComponent = ({ title, sub }: IFormLabelProps) => (
  <StyledFormLabel>
    {title}
    {sub && (
      <Typography
        color={sub.color}
        fontWeight={sub.fontWeight}
        marginLeft="5px"
      >
        {sub.text}
      </Typography>
    )}
  </StyledFormLabel>
)

export default FormLabelComponent
