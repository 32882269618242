import { configureStore } from '@reduxjs/toolkit'
import formInfoReducer from '../store/formInfo'
import appointmentInfoReducer from '../store/appointmentInfo'
import serviceCenterInfoReducer from '../store/serviceCenterInfo'
import serviceCenterByStateReducer from '../store/serviceCenterByState'
import zipCodeReducer from '../store/zipCode'
import identifyPatientReducer from '../store/identifyPatient'
import rescheduleReducer from '../store/reschedule'
import appointmentDurationReducer from '../store/appointmentDuration'
import invitationReducer from '../store/invitation'

const store = configureStore({
  reducer: {
    formInfo: formInfoReducer,
    appointmentInfo: appointmentInfoReducer,
    serviceCenterInfo: serviceCenterInfoReducer,
    serviceCenterByState: serviceCenterByStateReducer,
    zipCode: zipCodeReducer,
    identifyPatient: identifyPatientReducer,
    reschedule: rescheduleReducer,
    appointmentDuration: appointmentDurationReducer,
    invitation: invitationReducer,
  },
})

store.subscribe(() => {
  const state = store.getState()

  const centerName = state.serviceCenterInfo.Name ? state.serviceCenterInfo.Name : ''
  const centerAddress = state.serviceCenterInfo.Address ? state.serviceCenterInfo.Address : ''
  const patientNumber = state.reschedule.patientNumber?.toString() ?? ''
  const personDetails = state.formInfo.PersonDetails ? JSON.stringify(state.formInfo.PersonDetails) : ''
  const guardianDetails = state.formInfo.Guardian ? JSON.stringify(state.formInfo.Guardian) : ''
  const appointmentInfo = JSON.stringify(state.appointmentInfo)
  const zipCode = state.zipCode.zip || ''
  const identifyPatient = state.identifyPatient ? JSON.stringify(state.identifyPatient) : ''
  const { appointmentDuration } = state.appointmentDuration

  localStorage.setItem('centerName', centerName)
  localStorage.setItem('centerAddress', centerAddress)
  localStorage.setItem('patientNumber', patientNumber)
  localStorage.setItem('personDetails', personDetails)
  localStorage.setItem('guardianDetails', guardianDetails)
  localStorage.setItem('appointmentInfo', appointmentInfo)
  localStorage.setItem('zipCode', zipCode)
  localStorage.setItem('identifyPatient', identifyPatient)
  localStorage.setItem('appointmentDuration', String(appointmentDuration))
  localStorage.setItem('invitationPatient', JSON.stringify(state.invitation))
})

export default store
