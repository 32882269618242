import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { IStates } from '../app/types'

const serviceCenterState = localStorage.getItem('serviceCenterState')

const initialState: any = {
  serviceCenterByState: serviceCenterState || [],
}

export const serviceCenterByStateSlicer = createSlice({
  name: 'serviceCenterByState',
  initialState,
  reducers: {
    setServiceCenterByState: (state, action: PayloadAction<Array<IStates>>) => {
      state.serviceCenterByState = action.payload
    },
  },
})

export const { setServiceCenterByState } = serviceCenterByStateSlicer.actions

export default serviceCenterByStateSlicer.reducer
