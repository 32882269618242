export const SS_ENDPOINT = process.env.REACT_APP_SS_BASE_URL
export const DSIFIT_ENDPOINT = process.env.REACT_APP_DSIFIT_BASE_URL

export const MAX_LENGTH_ZIP_CODE = 5
export const PHONE_NUMBER_LENGTH = 10

export const DATE_PATTERNS = {
  iso_8601_date_time_no_seconds: 'YYYY-MM-DDTHH:mm',
  iso_8601_date_time: 'YYYY-MM-DDTHH:mm:ss',
  iso_8601_date: 'YYYY-MM-DD',
  greenway_date: 'MM/DD/YYYY',
  greenway_date_time: 'MM/DD/YYYY HH:mm:ss',
  greenway_date_time_no_seconds: 'MM/DD/YYYY HH:mm',
  long_date: 'dddd, MMMM D, YYYY',
  short_date: 'M/D/YYYY',
  time: 'h:mm',
  time_am_pm: 'h:mm A',
  time_24: 'HH:mm',
  month_full: 'MMMM',
  name_of_week: 'dd',
  day_of_month: 'DD',
}
export const NAME_PATTERN = /^[\s\p{Letter}'-]+$/u

export const CONTACT_NUMBER = {
  value: '844-447-5894',
  description: '(844) 447-5894',
  other: '+1-844-447-5894',
}

export const DEFAULT_MAP_CENTER = {
  lat: 39.06380844,
  lng: -94.89698792,
}

export const DEFAULT_LANGUAGE_VALUE = 'ENG'

export const NAME_INVALID_MESSAGE = 'Numbers and special characters are not allowed'

export const ERROR_MESSAGE = 'Sorry, there was an error with the system. Please fill out the following form, and we will contact you to schedule the appointment.'

export const APPOINTMENT_HOUR_DIFF = 2
export const APPOINTMENT_WEEK_DIFF = 3
export const APPOINTMENT_TIMERANGE_MINUTES = 40
export const APPOINTMENT_TIMERANGE_MINUTES_NONENGLISH = 50
export const APPOINTMENT_TIME_BOUNDS = {
  start: '07:00',
  end: '17:00',
  interval: 10, // minutes
}
export const TOKEN_EXPIRY_MILLISECONDS = 600000

// mobile
export const MAX_TABLET_DIMENSIONS = 960 // px
export const MAX_MOBILE_DIMENSIONS = 768 // px

// colors
export const PRIMARY_COLOR = '#ef7955'
export const PRIMARY_COLOR_HOVER = '#d15c38'
export const PRIMARY_COLOR_LIGHT = '#fff2f0'
export const SUB_COLOR = '#555555'
export const SUB_COLOR_LIGHT = '#808a94'
export const SECONDARY_COLOR = '#147f6e'
export const SECONDARY_COLOR_HOVER = '#0f5f53'
export const SECONDARY_COLOR_LIGHT = '#e7f2f0'
export const INFO_COLOR = '#0060a9'
export const INFO_COLOR_LIGHT = '#e5eff8'
export const ERROR_COLOR = '#eb5757'
export const FONT_COLOR = '#1b1a1a'
export const PROGRESS_BAR_TRAIL_COLOR = '#d9d9d9'
export const BLUE_CRANIAL_COLOR = '#005893'
export const LIGHT_BLUE_CRANIAL_COLOR = '#E5EFF8'
export const GREEN_CRANIAL_COLOR = '#147F6E'
export const LIGHT_GREEN_CRANIAL_COLOR = '#E7F2F0'

// fonts
export const DISPLAY_FONT_FAMILY = '"Libre Franklin", sans-serif'
export const BODY_FONT_FAMILY = '"Mulish", sans-serif'

export interface GenderLookup {
  LookupType: string;
  Code: 'M' | 'F' | 'U';
  Description: string;
  Order: number;
  Active: boolean;
  AlternateCodes: Array<string> | null;
}

export const GENDER_LOOKUP = {
  F: {
    LookupType: 'Gender',
    Code: 'F',
    Description: 'Female',
    Order: 1,
    Active: true,
    AlternateCodes: null,
  } as GenderLookup,
  M: {
    LookupType: 'Gender',
    Code: 'M',
    Description: 'Male',
    Order: 2,
    Active: true,
    AlternateCodes: null,
  } as GenderLookup,
  U: {
    LookupType: 'Gender',
    Code: 'U',
    Description: 'Unknown',
    Order: 3,
    Active: true,
    AlternateCodes: null,
  } as GenderLookup,
}

export interface RelationLookup {
  LookupType: string;
  Code: 'C' | 'R' | 'N' | 'O';
  Description: string;
  Order: number;
  Active: boolean;
  AlternateCodes: Array<string> | null;
}

export const RELATION_LOOKUP = {
  C: {
    LookupType: 'Relation',
    Code: 'C',
    Description: 'Child',
    Order: 1,
    Active: true,
    AlternateCodes: null,
  } as RelationLookup,
  R: {
    LookupType: 'Relation',
    Code: 'R',
    Description: 'Grandchild',
    Order: 11,
    Active: true,
    AlternateCodes: null,
  } as RelationLookup,
  N: {
    LookupType: 'Relation',
    Code: 'N',
    Description: 'Niece/Nephew',
    Order: 12,
    Active: true,
    AlternateCodes: null,
  } as RelationLookup,
  O: {
    LookupType: 'Relation',
    Code: 'O',
    Description: 'Other',
    Order: 16,
    Active: true,
    AlternateCodes: null,
  } as RelationLookup,
}

export interface CountryLookup {
  LookupType: string;
  Code: 'US' | 'CA' | 'MX';
  Description: string;
  Order: number;
  Active: boolean;
  AlternateCodes: Array<string> | null;
}

export const COUNTRY_LOOKUP = {
  CA: {
    LookupType: 'Country',
    Code: 'CA',
    Description: 'Canada',
    Order: 60,
    Active: true,
    AlternateCodes: null,
  } as CountryLookup,
  MX: {
    LookupType: 'Country',
    Code: 'MX',
    Description: 'Mexico',
    Order: 240,
    Active: true,
    AlternateCodes: null,
  } as CountryLookup,
  US: {
    LookupType: 'Country',
    Code: 'US',
    Description: 'United States',
    Order: 320,
    Active: true,
    AlternateCodes: null,
  } as CountryLookup,
}

// Guardian Relationship
const relationItems: [string, string][] = [
  ['C', 'Child'],
  ['R', 'Grandchild'],
  ['N', 'Niece/Nephew'],
  ['O', 'Other'],
]

export const RELATION_OPTIONS = relationItems.map(([code, description]) => ({
  value: code,
  label: description,
}))

interface LanguageDict {
  [key: string]: string;
}

export const LANGUAGE_DICT: LanguageDict = {
  SQI: 'Albanian',
  AMH: 'Amharic',
  ARA: 'Arabic',
  HYE: 'Armenian',
  RUP: 'Aromanian/Arumanian/Macedo-Romanian',
  BAN: 'Balinese',
  BEN: 'Bengali',
  MYA: 'Burmese',
  ZHO: 'Chinese',
  ZHA: 'Chuang/Zhuang',
  CRE: 'Cree',
  CRP: 'Creoles and pidgins',
  CPE: 'Creoles and pidgins, English based',
  CPF: 'Creoles and pidgins, French-based',
  CES: 'Czech',
  DAR: 'Dargwa',
  ENG: 'English',
  FAO: 'Faroese',
  FRA: 'French',
  GUJ: 'Gujarati',
  HAI: 'Haida',
  HAT: 'Haitian/Haitian Creole',
  JPN: 'Japanese',
  KAR: 'Karen languages',
  KOR: 'Korean',
  KUR: 'Kurdish',
  MDR: 'Mandar',
  MAN: 'Mandingo',
  MYN: 'Mayan languages',
  RON: 'Moldovan/Moldavian/Romanian',
  MON: 'Mongolian',
  MKH: 'Mon-Khmer languages',
  NEP: 'Nepali',
  PUS: 'Pashto/Pushto',
  FAS: 'Persian',
  POL: 'Polish',
  POR: 'Portuguese',
  PUN: 'Punjabi',
  RGA: 'Rohingya',
  RUS: 'Russian',
  SOM: 'Somali',
  SPA: 'Spanish',
  SWA: 'Swahili',
  THA: 'Thai',
  TIR: 'Tigrinya',
  TUR: 'Turkish',
  UKR: 'Ukrainian',
  URD: 'Urdu',
  UZB: 'Uzbek',
  VIE: 'Vietnamese',
  ZOM: 'Zomi',
}

export const LANGUAGE_OPTIONS = Object.keys(LANGUAGE_DICT).map((key: string) => ({
  value: key,
  label: LANGUAGE_DICT[key],
}))

export const STATE_OPTIONS = [
  {
    value: 'AL',
    label: 'Alabama',
    centers: [],
  },
  {
    value: 'AK',
    label: 'Alaska',
    centers: [],
  },
  {
    value: 'AZ',
    label: 'Arizona',
    centers: ['Glendale', 'Gilbert', 'Mesa', 'Phoenix', 'Tucson'],
  },
  {
    value: 'AR',
    label: 'Arkansas',
    centers: [],
  },
  {
    value: 'CA',
    label: 'California',
    centers: [
      'Brentwood (Los Angeles)',
      'Escondido',
      'Fresno',
      'Irvine',
      'Long Beach',
      'Los Feliz (Los Angeles)',
      'Mountain View',
      'National City',
      'Oakland',
      'Orange',
      'Pasadena',
      'Pomona',
      'Riverside',
      'Sacramento',
      'San Diego',
      'San Francisco',
      'San Jose',
      'Stockton',
      'Temecula',
      'Whittier',
    ],
  },
  {
    value: 'CO',
    label: 'Colorado',
    centers: ['Greenwood Village', 'Thornton'],
  },
  {
    value: 'CT',
    label: 'Connecticut',
    centers: ['Hartford', 'Milford'],
  },
  {
    value: 'DC',
    label: 'District of Columbia',
    centers: ['Annandale', 'National Harbor', 'Silver Spring'],
  },
  {
    value: 'DE',
    label: 'Delaware',
    centers: [],
  },
  {
    value: 'FL',
    label: 'Florida',
    centers: [
      'Boca Raton',
      'Davie',
      'Hollywood',
      'Jacksonville',
      'Jupiter',
      'South Miami',
      'Orlando',
      'Tampa',
    ],
  },
  {
    value: 'GA',
    label: 'Georgia',
    centers: ['Atlanta', 'Duluth', 'Marietta', 'Stockbridge'],
  },
  {
    value: 'HI',
    label: 'Hawaii',
    centers: [],
  },
  {
    value: 'ID',
    label: 'Idaho',
    centers: [],
  },
  {
    value: 'IL',
    label: 'Illinois',
    centers: [
      'Chicago',
      'Elgin',
      'Gurnee',
      'Lombard',
      'Orland Park',
      'Rockford',
      'Skokie',
    ],
  },
  {
    value: 'IN',
    label: 'Indiana',
    centers: ['Indianapolis'],
  },
  {
    value: 'IA',
    label: 'Iowa',
    centers: [],
  },
  {
    value: 'KS',
    label: 'Kansas',
    centers: [],
  },
  {
    value: 'KY',
    label: 'Kentucky',
    centers: ['Louisville'],
  },
  {
    value: 'LA',
    label: 'Louisiana',
    centers: [],
  },
  {
    value: 'MA',
    label: 'Massachusetts',
    centers: ['Boston West'],
  },
  {
    value: 'MD',
    label: 'Maryland',
    centers: ['Columbia', 'National Harbor', 'Silver Spring', 'Towson'],
  },
  {
    value: 'ME',
    label: 'Maine',
    centers: [],
  },
  {
    value: 'MI',
    label: 'Michigan',
    centers: ['Novi', 'Grand Rapids'],
  },
  {
    value: 'MN',
    label: 'Minnesota',
    centers: ['Minneapolis North Clinic (Coming Soon!)'],
  },
  {
    value: 'MO',
    label: 'Missouri',
    centers: ['Kansas City', 'St. Louis'],
  },
  {
    value: 'MS',
    label: 'Mississippi',
    centers: [],
  },
  {
    value: 'MT',
    label: 'Montana',
    centers: [],
  },
  {
    value: 'NC',
    label: 'North Carolina',
    centers: [
      'Cary',
      'Charlotte Ballantyne',
      'Charlotte Northlake',
      'Winston-Salem',
    ],
  },
  {
    value: 'ND',
    label: 'North Dakota',
    centers: [],
  },
  {
    value: 'NE',
    label: 'Nebraska',
    centers: [],
  },
  {
    value: 'NH',
    label: 'New Hampshire',
    centers: [],
  },
  {
    value: 'NM',
    label: 'New Mexico',
    centers: [],
  },
  {
    value: 'NV',
    label: 'Nevada',
    centers: ['Las Vegas'],
  },
  {
    value: 'NJ',
    label: 'New Jersey',
    centers: [
      'Cherry Hill',
      'Eatontown',
      'Edison',
      'Hackensack',
      'Morristown',
      'Kearny',
    ],
  },
  {
    value: 'NY',
    label: 'New York',
    centers: ['Hauppauge', 'Mineola', 'Scarsdale'],
  },
  {
    value: 'OH',
    label: 'Ohio',
    centers: ['Cincinnati', 'Columbus', 'Independence', 'Dayton'],
  },
  {
    value: 'OK',
    label: 'Oklahoma',
    centers: [],
  },
  {
    value: 'OR',
    label: 'Oregon',
    centers: [],
  },
  {
    value: 'PA',
    label: 'Pennsylvania',
    centers: ['Allentown', 'King Of Prussia', 'Pittsburgh'],
  },
  {
    value: 'RI',
    label: 'Rhode Island',
    centers: ['Providence'],
  },
  {
    value: 'SC',
    label: 'South Carolina',
    centers: ['Greenville'],
  },
  {
    value: 'SD',
    label: 'South Dakota',
    centers: [],
  },
  {
    value: 'TN',
    label: 'Tennessee',
    centers: ['Nashville'],
  },
  {
    value: 'TX',
    label: 'Texas',
    centers: [
      'Austin',
      'Dallas',
      'Fort Worth',
      'Grapevine',
      'Houston',
      'Mesquite',
      'Pearland',
      'San Antonio',
      'Shenandoah',
    ],
  },
  {
    value: 'UT',
    label: 'Utah',
    centers: ['West Valley City'],
  },
  {
    value: 'VA',
    label: 'Virginia',
    centers: ['Annandale', 'Fredericksburg', 'Norfolk', 'Richmond', 'Sterling'],
  },
  {
    value: 'VM',
    label: 'Vermont',
    centers: [],
  },
  {
    value: 'WA',
    label: 'Washington',
    centers: ['Bellevue'],
  },
  {
    value: 'WI',
    label: 'Wisconsin',
    centers: ['Madison (Licensed Center)', 'Milwaukee'],
  },
  {
    value: 'WV',
    label: 'West Virginia',
    centers: [],
  },
  {
    value: 'WY',
    label: 'Wyoming',
    centers: [],
  },
]

export const STATE_CENTER_OPTIONS = STATE_OPTIONS.reduce(
  (acc, { value: stateCode, centers }) => {
    const centerArray = centers.map((center) => ({
      value: center,
      label: center,
    }))
    acc[stateCode] = centerArray
    return acc
  },
  {} as { [key: string]: { value: string; label: string }[] },
)

export const STATE_CODES = STATE_OPTIONS.map(
  ({ value: stateCode }) => stateCode,
)

export const SUBS = {
  optional: { text: '(Optional)', color: SUB_COLOR, fontWeight: 'bold' },
  required: { text: '*', color: ERROR_COLOR, fontWeight: 'regular' },
}

export const MAX_LENGTHS = {
  FIRST_NAME: 35,
  LAST_NAME: 35,
  FIRST_LINE_ADDRESS: 40,
  SECOND_LINE_ADDRESS: 40,
  CITY: 35,
  ZIP_CODE: 5,
  EMAIL: 254,
  PHONE_NUMBER: 10,
}

export const SUPPORTED_LANGUAGES = {
  en: 'English',
  es: 'Español',
}

export const INITIAL_ROLLOUT_SS = JSON.parse(process.env.INITIAL_ROLLOUT_SS ?? '{}')
