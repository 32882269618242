import { IProgress } from '../app/types'
import { PROGRESS_BAR_TRAIL_COLOR, INFO_COLOR } from '../app/constants'

const ProgressBar = ({
  progress,
  maxProgress,
}: IProgress) => (
  <div style={{
    display: 'grid', gridTemplateColumns: `repeat(${maxProgress}, 1fr)`, gap: '3px', padding: '10px 0',
  }}
  >
    {Array.from({ length: maxProgress }).map((_, index) => (
      <div
        key={`progress-${index + 1}-${maxProgress}`}
        style={{
          backgroundColor: index + 1 <= progress ? INFO_COLOR : PROGRESS_BAR_TRAIL_COLOR,
          height: '5px',
          width: '100%',
        }}
      />
    ))}
  </div>
)

export default ProgressBar
