import { Button } from '@mui/material'
import styled from '@emotion/styled'
import { INFO_COLOR } from '../app/constants'
import { IBackButton } from '../app/types'
import usePathTranslation from '../hooks/pathTranslation'

const StyledButton = styled(Button)`
  font-size: 16px;
  text-transform: none;
  width: fit-content;
  align-self: center;
  &:hover {
    background-color: transparent;
  }
`

const NextButton = ({ onClick = () => {} }: IBackButton) => {
  const { t } = usePathTranslation()
  return (
    <StyledButton
      disableRipple
      onClick={onClick}
      style={{ color: INFO_COLOR }}
      variant="text"
    >
      {t('dsi_appointment_view.back_button')}
    </StyledButton>
  )
}

export default NextButton
