import { Box, Typography } from '@mui/material'
import styled from '@emotion/styled'
import logo from '../../assets/images/404.jpg'

const StyledTypography = styled(Typography)`
  font-size: 27px;
  font-weight: bold;
  height: 100%;
`

const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 48px;
`

const NotFound = () => (
  <StyledSection>
    <StyledTypography>
      404 Error! Page not found!
    </StyledTypography>
    <Box
      alt="not found"
      component="img"
      src={logo}
      sx={{
        bottom: 0,
        maxWidth: '100%',
        position: 'absolute',
      }}
    />
  </StyledSection>
)

export default NotFound
