import styled from '@emotion/styled'
import { Box, CircularProgress } from '@mui/material'
import { AxiosError } from 'axios'
import { useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { validateInvitationId } from '../../../api/postInvitationInformation'

const ValidateInvitation = () => {
  const params = useParams()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const lang = searchParams.get('lang') ?? 'en'
  const { invitationId } = params as { invitationId: string }

  useEffect(() => {
    if (invitationId) {
      validateInvitationId(invitationId).then(() => {
        navigate(`../../${lang}/dsi-fit/invitation-form`, { relative: 'path', state: { invitationId } })
      }).catch((error: AxiosError) => {
        if (error.response?.status === 400) {
          navigate(`../../${lang}/dsi-fit/invitation-expired`, { relative: 'path' })
        } else {
          navigate(`../../${lang}/dsi-fit/technical-difficulties`, { relative: 'path' })
        }
      })
    }
  }, [])

  return (
    <LoadingWrapper>
      <LoadingBox
        size={50}
      />
    </LoadingWrapper>
  )
}

const LoadingBox = styled(CircularProgress)`
  position: absolute;
  display: flex;
  align-self: center;
  margin-bottom: 1rem;
`

const LoadingWrapper = styled(Box)`
  margin-top: 20%;
  display: flex;
  align-self: center;
  flex-direction: column;
  padding-bottom: 10%;
`

export default ValidateInvitation
