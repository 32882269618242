import { Link, Typography } from '@mui/material'
import { Trans } from 'react-i18next'
import { CONTACT_NUMBER, DISPLAY_FONT_FAMILY } from '../../../app/constants'
import { CalendarExpired } from '../../../assets/icons'
import AppBar from '../../../components/AppBar'
import ViewContainer from '../../../components/ViewContainer'
import usePathTranslation from '../../../hooks/pathTranslation'

const TechnicalDifficulties = () => {
  const { t } = usePathTranslation()
  return (
    <ViewContainer>
      <CalendarExpired />
      <AppBar
        description={(
          <Typography
            sx={{
              fontFamily: DISPLAY_FONT_FAMILY,
              padding: '0px 0',
              textAlign: 'center',
              whiteSpace: 'normal',
              marginTop: '20px',
            }}
          >
            <Trans
              components={[
                <Link
                  href={`tel:${CONTACT_NUMBER.value}`}
                />,
                <span style={{ textAlign: 'center' }} />,
              ]}
              i18nKey="technical_difficulties.description"
              t={t}
              values={{
                clinicNumber: CONTACT_NUMBER.description,
              }}
            />
          </Typography>
                )}
        maxProgress={5}
        progress={0}
        title={t('technical_difficulties.title')}
      />
    </ViewContainer>
  )
}

export default TechnicalDifficulties
