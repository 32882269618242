import axios from 'axios'
import dayjs from 'dayjs'
import { Appointments, Invitation } from '../store/invitation'
import { DSIFIT_ENDPOINT } from '../app/constants'

const postScheduleDSIFitAppointment = async (
  invitation: Invitation,
  appointment: Appointments,
  setLoading: (loading: boolean) => void,
) => {
  const URL = `${DSIFIT_ENDPOINT}/api/appointment-set`
  const dsiDate = dayjs(appointment.dsiAppointment)
  const fitDate = dayjs(appointment.fitAppointment)

  setLoading(true)
  const [status, response] = await axios
    .post(URL, {
      invitation_id: invitation.invitationId,
      language: invitation.patient.language ?? 'ENG',
      appointments: [
        {
          date: dsiDate.format('MM/DD/YYYY'),
          time: dsiDate.format('HH:mm:ss'),
          clinic_id: invitation.clinic.id,
          program: 'dsi',
        },
        {
          date: fitDate.format('MM/DD/YYYY'),
          time: fitDate.format('HH:mm:ss'),
          clinic_id: invitation.clinic.id,
          program: 'fit',
        },
      ],
    })
    .then((r) => [r.status, r.data])
    .catch((error) => {
      if (error.response) {
        return [error.response.status, error.response.data]
      }
      return [500, '']
    }).finally(() => {
      setLoading(false)
    })

  if (status !== 200) {
    throw new Error(response)
  }
  return response
}
export default postScheduleDSIFitAppointment
