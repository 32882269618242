import { Dispatch } from '@reduxjs/toolkit'
import { setAppointmentInfo, setServiceCenterID } from '../store/appointmentInfo'
import { setFormInfo } from '../store/formInfo'
import { setServiceCenterInfo } from '../store/serviceCenterInfo'
import { DEFAULT_LANGUAGE_VALUE } from './constants'
import { setPatientInfo } from '../store/reschedule'
import { setInvitationPatient } from '../store/invitation'

const clearStorage = (dispatch: Dispatch) => {
  dispatch(setAppointmentInfo({
    ServiceCenterID: 0,
    AppointmentDateTime: '',
  }))
  dispatch(setServiceCenterID(0))
  dispatch(setFormInfo({
    patientFirstName: '',
    patientLastName: '',
    dateOfBirth: null,
    email: '',
    message: '',
    gender: null,
    language: DEFAULT_LANGUAGE_VALUE,
    firstLine: '',
    secondLine: '',
    city: '',
    stateCode: '',
    zipCode: '',
    country: 'US',
    countryCode: '',
    phoneNumber: '',
    guardianFirstName: '',
    guardianLastName: '',
    guardianRelation: null,
    guardianPhoneNumber: '',
    guardianPhoneCode: '',
    guardianEmail: '',
    guardian2FirstName: '',
    guardian2LastName: '',
    guardian2Relation: null,
    guardian2PhoneNumber: '',
    guardian2PhoneCode: '',
    guardian2Email: '',
  }))
  dispatch(setServiceCenterInfo({
    Name: null,
    Address: null,
  }))
  dispatch(setPatientInfo({
    personID: 0,
    patientNumber: '',
  }))

  localStorage.removeItem('centerName')
  localStorage.removeItem('centerAddress')
  localStorage.removeItem('patientNumber')
  localStorage.removeItem('personDetails')
  localStorage.removeItem('guardianDetails')
  localStorage.removeItem('appointmentInfo')
  localStorage.removeItem('zipCode')

  deleteCookie('CampaignEntryURLData', '/', '.cranialtech.com')
}

export const clearPatientInvitation = (dispatch: Dispatch) => {
  dispatch(setInvitationPatient({
    patient: {
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      email: '',
      language: '',
    },
    appointmentsSpecification: {
      dsiCutOffDate: '',
    },
    clinic: {
      id: 0,
      address: '',
      geolocation: { lat: 0, lng: 0 },
    },
    invitationId: null,
  }))
}

function deleteCookie(name: string, path: string, domain: string) {
  if (getCookie(name)) {
    document.cookie = `${name}=${
      (path) ? `;path=${path}` : ''
    }${(domain) ? `;domain=${domain}` : ''
    };expires=Thu, 01 Jan 1970 00:00:01 GMT`
  }
}

function getCookie(name: string) {
  return document.cookie.split(';').some((c) => c.trim().startsWith(`${name}=`))
}

export default clearStorage
