import { MAX_LENGTHS, SUBS } from '../app/constants'
import { FormInputProps, IFormItem } from '../app/types'
import usePathTranslation from '../hooks/pathTranslation'
import DateOfBirthHelp from './DateOfBirthHelp'
import FormInputDate from './FormInputDate'
import FormInputText from './FormInputText'
import FormSection from './FormSection'

interface PatientInfoProps {
  description?: JSX.Element
}

const PatientBasicInfoForm = ({
  control,
  errors,
  required,
  description,
}: FormInputProps & PatientInfoProps) => {
  const { t } = usePathTranslation()
  const identifyPatient: IFormItem[] = [
    {
      inputComponent: (
        <FormInputText
          control={control}
          errors={errors}
          maxLength={MAX_LENGTHS.FIRST_NAME}
          name="patientFirstName"
          placeholder={t('patient_information_form.first_name.placeholder')}
          required={required}
        />
      ),
    },
    {
      inputComponent: (
        <FormInputText
          control={control}
          errors={errors}
          maxLength={MAX_LENGTHS.LAST_NAME}
          name="patientLastName"
          placeholder={t('patient_information_form.last_name.placeholder')}
          required={required}
        />
      ),
    },
    {
      inputComponent: (
        <FormInputDate
          control={control}
          errors={errors}
          helperText={<DateOfBirthHelp />}
          name="dateOfBirth"
          placeholder={t('patient_information_form.date_of_birth.placeholder')}
          required={required}
        />
      ),
    },
  ]

  return (
    <FormSection
      data={identifyPatient}
      description={description}
      sub={SUBS.required}
      title={t('patient_information_form.title')}
    />
  )
}

export default PatientBasicInfoForm
