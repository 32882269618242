import { PropsWithChildren } from 'react'
import { Typography } from '@mui/material'
import { DISPLAY_FONT_FAMILY } from '../app/constants'

const AppointmentDescription = ({ children }: PropsWithChildren) => (
  <Typography
    sx={{
      fontFamily: DISPLAY_FONT_FAMILY,
      padding: '0px 0',
      textAlign: 'justify',
      whiteSpace: 'normal',
    }}
  >
    {children}
  </Typography>
)

export default AppointmentDescription
