import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'
import { RouterProvider } from 'react-router-dom'
import ReactGA from 'react-ga4'
import TagManager from 'react-gtm-module'
import router from './react-router'
import store from './store'
import theme from './theme'

const queryClient = new QueryClient()
const measurementID = process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID
const streamID = process.env.REACT_APP_GOOGLE_ANALYTICS_STREAM_ID
const tagID = process.env.REACT_APP_GOOGLE_ANALYTICS_TAG_CONTAINER_ID

if (measurementID && streamID && tagID) {
  const tagManagerArgs = {
    gtmId: tagID,
    dataLayer: {
      userId: streamID,
    },
  }
  ReactGA.initialize(measurementID, {
    gaOptions: {
      userId: streamID,
    },
  })
  TagManager.initialize(tagManagerArgs)
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search, title: 'CranialTech Scheduling' })
}

const ConfiguredApp = () => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </Provider>
  </ThemeProvider>
)

export default ConfiguredApp
