import '@fontsource/mulish'
import '@fontsource/libre-franklin'
import { createTheme } from '@mui/material/styles'
import {
  PRIMARY_COLOR,
  PRIMARY_COLOR_HOVER,
  SECONDARY_COLOR,
  SECONDARY_COLOR_HOVER,
  INFO_COLOR,
  ERROR_COLOR,
  FONT_COLOR,
  BODY_FONT_FAMILY,
  MAX_TABLET_DIMENSIONS,
  MAX_MOBILE_DIMENSIONS,
} from './constants'

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: false;
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true;
    tablet: true;
    desktop: true;
  }
}

const theme = createTheme({
  breakpoints: {
    values: {
      mobile: 0,
      tablet: MAX_MOBILE_DIMENSIONS,
      desktop: MAX_TABLET_DIMENSIONS,
    },
  },
  typography: {
    fontFamily: BODY_FONT_FAMILY,
    allVariants: {
      color: FONT_COLOR,
    },
  },
  palette: {
    primary: {
      main: PRIMARY_COLOR,
      dark: PRIMARY_COLOR_HOVER,
    },
    secondary: {
      main: SECONDARY_COLOR,
      dark: SECONDARY_COLOR_HOVER,
    },
    info: {
      main: INFO_COLOR,
    },
    error: {
      main: ERROR_COLOR,
    },
  },
  components: {
    MuiLink: {
      defaultProps: {
        color: INFO_COLOR,
        underline: 'none',
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          // Remove arrows from number input.
          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
          },
          // Remove arrows from Firefox.
          '& input[type=number]': {
            MozAppearance: 'textfield',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        },

      },
    },
  },
})

export default theme
