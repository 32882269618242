import { PayloadAction, createSlice } from '@reduxjs/toolkit'

const appointmentDuration = localStorage.getItem('appointmentDuration')

const initialState = {
  appointmentDuration: Number(appointmentDuration) || 40,
}

export const appointmentDurationSlicer = createSlice({
  name: 'appointmentDuration',
  initialState,
  reducers: {
    setAppointmentDuration: (state, action: PayloadAction<number>) => {
      state.appointmentDuration = action.payload
    },
  },
})

export const { setAppointmentDuration } = appointmentDurationSlicer.actions

export default appointmentDurationSlicer.reducer
