import { FormHelperText } from '@mui/material'
import { ERROR_COLOR } from '../app/constants'

const FormErrorComponent = ({ message }: { message: string }) => (
  <FormHelperText sx={{ color: ERROR_COLOR, marginTop: '4px' }}>
    {message}
  </FormHelperText>
)

export default FormErrorComponent
