import { Box, Typography } from '@mui/material'

const PlaceholderComponent = ({
  placeholder,
  required,
}: {
  placeholder: string;
  required?: boolean;
}) => (
  <Box
    alignContent="left"
    display="flex"
  >
    <Typography sx={{ opacity: 0.5 }}>{placeholder}</Typography>
    {required && (
      <Typography
        color="error"
        marginLeft="5px"
      >
        *
      </Typography>
    )}
  </Box>
)

export default PlaceholderComponent
