import { Controller } from 'react-hook-form'
import { TextField } from '@mui/material'
import validator from 'validator'
import ErrorComponent from './FormErrorComponent'
import PlaceholderComponent from './FormPlaceholderComponent'
import HelperTextComponent from './FormHelperTextComponent'
import {
  PHONE_NUMBER_LENGTH, MAX_LENGTH_ZIP_CODE,
} from '../app/constants'
import data from '../utils/state_zipcode.json'

const FormInputText = ({
  control,
  errors,
  isMultiline,
  helperText,
  maxLength,
  name,
  placeholder,
  required,
  type,
  getAddressValues,
  pattern,
}: {
  control: any;
  errors: any;
  isMultiline?: boolean;
  helperText?: string | JSX.Element;
  maxLength?: number;
  name: string;
  placeholder: string;
  required?: boolean;
  type?: 'text' | 'number' | 'email' | 'tel' | 'zipcode';
  getAddressValues?: any;
  pattern?: {
    value: RegExp;
    message: string;
  };
}) => {
  const checkMatchStateZipcode = (zipcode: string, state: string) => {
    const stateMatched = data[zipcode as keyof typeof data]
    if (!stateMatched) return true
    return stateMatched === state
  }
  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({
          field: { onChange, value, ref },
          fieldState: { error },
        }) => (
          <TextField
            autoComplete="off"
            color="info"
            error={!!error}
            fullWidth
            helperText={
            helperText && !errors[name] ? (
              <HelperTextComponent helperText={helperText} />
            ) : undefined
          }
            InputLabelProps={{ shrink: false }}
            inputRef={ref}
            label={
            !value && (
              <PlaceholderComponent
                placeholder={placeholder}
                required={required}
              />
            )
          }
            multiline={isMultiline}
            onChange={(v) => {
              if (type === 'tel' || type === 'zipcode') {
                const isNumber = /^\d*$/.test(v.target.value)
                if (!isNumber) return

                const isValidPhone = type === 'tel' && v.target.value.length <= PHONE_NUMBER_LENGTH
                const isValidZip = type === 'zipcode' && v.target.value.length <= MAX_LENGTH_ZIP_CODE
                if (isValidPhone || isValidZip) {
                  onChange(v.target.value)
                }
              } else {
                onChange(v)
              }
            }}
            rows={3} // Only for multiline
            size="small"
            value={value}
            variant="outlined"
            {...(maxLength && { inputProps: { maxLength } })}
          />
        )}
        rules={{
          minLength:
          type === 'tel' || type === 'zipcode'
            ? {
              value:
                  type === 'tel' ? PHONE_NUMBER_LENGTH : MAX_LENGTH_ZIP_CODE,
              message:
                  type === 'tel'
                    ? `Phone number must be ${PHONE_NUMBER_LENGTH} characters.`
                    : `Zip code must be ${MAX_LENGTH_ZIP_CODE} characters.`,
            }
            : undefined,
          required: required && 'This field is required.',
          pattern,
          validate: (value) => {
            if (type === 'email' && value && !validator.isEmail(value)) {
              return 'Invalid email.'
            }
            if (type === 'zipcode') {
              const values = getAddressValues()
              const isMatched = checkMatchStateZipcode(value, values.stateCode)
              if (!isMatched) {
                return `Zipcode not found in ${values.stateCode}`
              }
            }
            const regex = /^[a-zA-Z0-9áéíóúàèìòùüñÁÉÍÓÚÜÀÈÌÒÙÑäëïöüÄËÏÖÜ.,;:¡!¿?"'&()_\-@/$#^|{}<> ]*$/
            if (regex.test(value)) {
              return true
            }
            return 'Invalid Value'
          },
        }}
      />
      {errors[name] && <ErrorComponent message={errors[name].message} />}
    </>
  )
}

FormInputText.defaultProps = {
  isMultiline: false,
  required: false,
  type: 'text',
}

export default FormInputText
