import { Box } from '@mui/material'

const HelperTextComponent = ({
  helperText,
}: {
  helperText: string | JSX.Element;
}) => (typeof helperText === 'string' ? (
  <Box
    component="span"
    style={{ opacity: 0.6 }}
  >
    {helperText}
  </Box>
) : (
  helperText
))

export default HelperTextComponent
