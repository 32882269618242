import { Divider, Grid, Typography } from '@mui/material'
import styled from '@emotion/styled'
import GppGoodIcon from '@mui/icons-material/GppGood'
import { MAX_MOBILE_DIMENSIONS, SUB_COLOR, SUB_COLOR_LIGHT } from '../app/constants'
import ClinicNumberComponent from './ClinicNumberComponent'
import usePathTranslation from '../hooks/pathTranslation'

const StyledItem = styled(Grid)`
  text-align: center;
  padding: 10px 20px;
`

const StyledInfo = styled(Grid)`
  padding-top: 65px;
  justify-content: center;
  padding-bottom: 30px;

  @media (min-width: ${MAX_MOBILE_DIMENSIONS}px) {
    padding-top: 75px;
  }
`

const StyledInfoMessage = styled(Typography)`
  color: ${SUB_COLOR};
  font-size: 14px;
`

const StyledIcon = styled(GppGoodIcon)`
  font-size: 30px;
  color: ${SUB_COLOR_LIGHT};
`

const ViewContainer = (props: {
  children: React.ReactNode,
  clinicNumberInFooter?: boolean,
  footerDivider?: boolean
}) => {
  const { children, clinicNumberInFooter, footerDivider } = props
  const { t } = usePathTranslation()

  return (
    <Grid container>
      <Grid
        desktop={3}
        item
        mobile={0}
      />
      <StyledItem
        desktop={6}
        item
        mobile={12}
      >
        {children}

        <StyledInfo container>
          <Grid item>
            {(footerDivider ?? false) && <Divider />}
            {(clinicNumberInFooter ?? false) && <ClinicNumberComponent />}
            <StyledIcon />
            <StyledInfoMessage>
              {t('footer.secure_information_description')}
              .
            </StyledInfoMessage>
          </Grid>
        </StyledInfo>
      </StyledItem>
      <Grid
        desktop={3}
        item
        mobile={0}
      />
    </Grid>
  )
}
export default ViewContainer
