import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { IFormValues } from '../../../app/types'

const useFormMutation = () => {
  const patient = useSelector((state: any) => state.invitation.patient)

  const {
    control, formState, handleSubmit,
  } = useForm<IFormValues>({
    defaultValues: {
      patientFirstName: patient?.firstName || '',
      patientLastName: patient?.lastName || '',
    },
  })
  const { errors } = formState
  const [isLoading] = useState(false)

  return {
    control,
    errors,
    handleSubmit,
    isLoading,
  }
}

export default useFormMutation
