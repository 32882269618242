import axios from 'axios'
import { SS_ENDPOINT } from '../app/constants'

async function getAvailableAppointmentsSlots(
  params: string,
) {
  const resp = axios.get(
    `${SS_ENDPOINT}/api/get-available-appointments-slots?${params}`,
  )
  return resp
}

export default getAvailableAppointmentsSlots
