import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { IZipCode } from '../app/types'

const zipCode = localStorage.getItem('zipCode')

const initialState: IZipCode = {
  zip: zipCode ? zipCode.toString() : '',
}

export const zipCodeSlicer = createSlice({
  name: 'zipCode',
  initialState,
  reducers: {
    setZipCode: (state, action: PayloadAction<string>) => {
      state.zip = action.payload
    },
  },
})

export const { setZipCode } = zipCodeSlicer.actions

export default zipCodeSlicer.reducer
