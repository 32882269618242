import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import type { Dayjs } from 'dayjs'

const invitationPatient = localStorage.getItem('invitationPatient')

const initialState: Nullable<Invitation> & Nullable<Appointments> = {
  patient: invitationPatient ? JSON.parse(invitationPatient).patient : null,
  clinic: invitationPatient ? JSON.parse(invitationPatient).clinic : null,
  appointmentsSpecification:
    invitationPatient ? JSON.parse(invitationPatient).appointmentsSpecification : null,
  invitationId: invitationPatient ? JSON.parse(invitationPatient).invitationId : null,

  dsiAppointment: invitationPatient ? JSON.parse(invitationPatient).dsiAppointment : null,
  fitAppointment: invitationPatient ? JSON.parse(invitationPatient).fitAppointment : null,
}

export const invitationPatientSlicer = createSlice({
  name: 'invitationPatient',
  initialState,
  reducers: {
    setInvitationPatient: (state, action: PayloadAction<Invitation>) => {
      state.patient = action.payload.patient
      state.clinic = action.payload.clinic
      state.appointmentsSpecification = action.payload.appointmentsSpecification
      state.invitationId = action.payload.invitationId ?? state.invitationId
    },

    setDsiAppointment: (state, action: PayloadAction<string>) => {
      state.dsiAppointment = action.payload
    },

    setFitAppointment: (state, action: PayloadAction<string>) => {
      state.fitAppointment = action.payload
    },
  },
})

export const {
  setInvitationPatient,
  setDsiAppointment,
  setFitAppointment,
} = invitationPatientSlicer.actions

export default invitationPatientSlicer.reducer

// definitions
type Patient = {
  firstName: string
  lastName: string
  dateOfBirth?: Date | Dayjs | string | null
  email: string
  language: string
}

export type Clinic = {
  id: number
  address: string
  geolocation: {
    lat: number
    lng: number
  }
}

type AppointmentsSpecification = {
  dsiCutOffDate: string
}

export type Invitation = {
  patient: Patient
  clinic: Clinic
  appointmentsSpecification: AppointmentsSpecification

  invitationId: string | null

  dsiAppointment?: string
  fitAppointment?: string
}

export type Appointments = {
  dsiAppointment: string
  fitAppointment: string
}

type Nullable<T> = {
  [K in keyof T]: T[K] | null
}
