import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpBackend, { HttpBackendOptions } from 'i18next-http-backend'
import enJSON from './locale/en.json'
import esJSON from './locale/es.json'

i18n.use(HttpBackend).use(initReactI18next).init<HttpBackendOptions>({
  load: 'languageOnly',
  resources: {
    en: { ...enJSON },
    es: { ...esJSON },
  },
  fallbackLng: 'en',
  supportedLngs: ['en', 'es'],
})
