import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import { Trans } from 'react-i18next'
import AppBar from '../../../components/AppBar'
import AppointmentDescription from '../../../components/AppointmentDescription'
import AppointmentManager from '../../../components/AppointmentManager'
import BackButton from '../../../components/BackButton'
import NextButton from '../../../components/NextButton'
import ViewContainer from '../../../components/ViewContainer'
import useClinicAppointmentSelector from '../../../hooks/clinicAppointmentSelector'
import { setDsiAppointment } from '../../../store/invitation'
import usePathTranslation from '../../../hooks/pathTranslation'
import { BLUE_CRANIAL_COLOR, LIGHT_BLUE_CRANIAL_COLOR } from '../../../app/constants'

const ScheduleDSIAppointment = () => {
  const { t } = usePathTranslation()
  const dispatch = useDispatch()
  const invitation = useSelector((state: any) => state.invitation)

  const endDate = dayjs(invitation.appointmentsSpecification.dsiCutOffDate).format('MM/DD/YYYY')
  const clinicAppointmentSelector = useClinicAppointmentSelector(
    'DSI',
    invitation,
    [null, endDate],
  )
  const navigate = useNavigate()

  if (clinicAppointmentSelector.availableDates.length === 0) {
    navigate('../no-dates-available', { relative: 'path' })
  }

  return invitation.clinic == null ? null : (
    <ViewContainer
      clinicNumberInFooter
      footerDivider
    >
      <AppBar
        buttonBackAction={() => {
          navigate('../invitation-form', { relative: 'path', state: { invitationId: invitation.invitationId } })
        }}
        description={(
          <AppointmentDescription>
            <Trans
              components={[
                <span style={{ whiteSpace: 'nowrap' }} />,
              ]}
              i18nKey="dsi_appointment_view.subtitle"
              t={t}
            />
          </AppointmentDescription>
)}
        maxProgress={5}
        progress={2}
        title={t('dsi_appointment_view.title')}
      />
      <AppointmentManager
        appointmentDetails={{
          availableDateDescription: t('appointment_manager.available_dates.fit.subtitle'),
        }}
        appointmentSelector={clinicAppointmentSelector}
        appointmentStyle={{
          backgroundColor: LIGHT_BLUE_CRANIAL_COLOR,
          selectedBackgroundColor: BLUE_CRANIAL_COLOR,
          textColor: BLUE_CRANIAL_COLOR,
          selectedTextColor: 'white',
        }}
      />
      <VerticalLayout>
        <NextButton
          isDisabled={!clinicAppointmentSelector.isNextButtonEnabled}
          onClick={() => {
            dispatch(setDsiAppointment(`${clinicAppointmentSelector.selectedDate} ${clinicAppointmentSelector.selectedTime}`))
            navigate('../fit-appointment', { relative: 'path' })
          }}
          secondary
          text={t('dsi_appointment_view.button')}
        />
        <BackButton onClick={() => {
          navigate('../invitation-form', { relative: 'path', state: { invitationId: invitation.invitationId } })
        }}
        />
      </VerticalLayout>
    </ViewContainer>
  )
}

const VerticalLayout = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  width: 100%;
`

export default ScheduleDSIAppointment
