export const LocationIcon = () => (
  <svg
    fill="none"
    height="21"
    viewBox="0 0 20 21"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6667 8.83366C16.6667 13.8337 10 18.8337 10 18.8337C10 18.8337 3.33337 13.8337 3.33337 8.83366C3.33337 7.06555 4.03575 5.36986 5.286 4.11961C6.53624 2.86937 8.23193 2.16699 10 2.16699C11.7682 2.16699 13.4638 2.86937 14.7141 4.11961C15.9643 5.36986 16.6667 7.06555 16.6667 8.83366Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 11.333C11.3807 11.333 12.5 10.2137 12.5 8.83301C12.5 7.4523 11.3807 6.33301 10 6.33301C8.61929 6.33301 7.5 7.4523 7.5 8.83301C7.5 10.2137 8.61929 11.333 10 11.333Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const CalendarIcon = () => (
  <svg
    fill="none"
    height="21"
    viewBox="0 0 20 21"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8333 3.83301H4.16667C3.24619 3.83301 2.5 4.5792 2.5 5.49967V17.1663C2.5 18.0868 3.24619 18.833 4.16667 18.833H15.8333C16.7538 18.833 17.5 18.0868 17.5 17.1663V5.49967C17.5 4.5792 16.7538 3.83301 15.8333 3.83301Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3334 2.16699V5.50033"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.66663 2.16699V5.50033"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.5 8.83301H17.5"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>

)

export const ClockIcon = () => (
  <svg
    fill="none"
    height="21"
    viewBox="0 0 20 21"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99996 18.8337C14.6023 18.8337 18.3333 15.1027 18.3333 10.5003C18.3333 5.89795 14.6023 2.16699 9.99996 2.16699C5.39759 2.16699 1.66663 5.89795 1.66663 10.5003C1.66663 15.1027 5.39759 18.8337 9.99996 18.8337Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 5.5V10.5L13.3333 12.1667"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const CalendarExpired = () => (
  <svg
    fill="none"
    height="36"
    viewBox="0 0 24 24"
    width="36"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 13V6C21 5.46957 20.7893 4.96086 20.4142 4.58579C20.0391 4.21071 19.5304 4 19 4H5C4.46957 4 3.96086 4.21071 3.58579 4.58579C3.21071 4.96086 3 5.46957 3 6V20C3 20.5304 3.21071 21.0391 3.58579 21.4142C3.96086 21.7893 4.46957 22 5 22H13"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M16 2V6"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M8 2V6"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M3 10H21"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M17 17L22 22"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M17 22L22 17"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>

)
