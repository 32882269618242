import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { IIdentifyPatientValues } from '../app/types'

const identifyPatient = localStorage.getItem('identifyPatient')

const initialState: IIdentifyPatientValues = {
  patientFirstName: identifyPatient ? JSON.parse(identifyPatient).patientFirstName : null,
  patientLastName: identifyPatient ? JSON.parse(identifyPatient).patientLastName : null,
  patientZipCode: identifyPatient ? JSON.parse(identifyPatient).patientZipCode : null,
  appointmentList: identifyPatient ? JSON.parse(identifyPatient).appointmentList : null,
}

export const identifyPatientSlicer = createSlice({
  name: 'identifyPatient',
  initialState,
  reducers: {
    setIdentifyPatient: (state, action: PayloadAction<IIdentifyPatientValues>) => {
      const { payload } = action
      state.patientFirstName = payload.patientFirstName
      state.patientLastName = payload.patientLastName
      state.patientZipCode = payload.patientZipCode
      state.appointmentList = payload.appointmentList
    },
  },
})

export const { setIdentifyPatient } = identifyPatientSlicer.actions

export default identifyPatientSlicer.reducer
