import dayjs from 'dayjs'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { Trans } from 'react-i18next'
import AppBar from '../../../components/AppBar'
import AppointmentDescription from '../../../components/AppointmentDescription'
import ScheduledAppointmentInfo from '../../../components/ScheduledAppointmentInfo'
import ViewContainer from '../../../components/ViewContainer'
import NextButton from '../../../components/NextButton'
import BackButton from '../../../components/BackButton'
import postScheduleDSIFitAppointment from '../../../api/postScheduleDSIFitAppointment'
import useDialog from '../../../hooks/dialog'
import Dialog from '../../../components/Dialog'
import usePathTranslation from '../../../hooks/pathTranslation'
import { BLUE_CRANIAL_COLOR, GREEN_CRANIAL_COLOR } from '../../../app/constants'

const ConfirmScheduledAppointments = () => {
  const { t } = usePathTranslation()
  const [loading, setLoading] = useState(false)
  const invitation = useSelector((state: any) => state.invitation)
  const alertHook = useDialog()
  const navigate = useNavigate()

  useEffect(() => {
    if (!invitation.patient.firstName) {
      navigate('../invitation-form', { relative: 'path', state: { invitationId: invitation.invitationId } })
    }
  }, [])
  return (
    <ViewContainer
      clinicNumberInFooter
      footerDivider
    >
      <AppBar
        buttonBackAction={() => {
          navigate('../fit-appointment', { relative: 'path' })
        }}
        maxProgress={5}
        progress={4}
        title={t('dsi_fit_almost_done_view.title')}
      />
      <AppointmentDescription>
        <Trans
          components={[<strong />]}
          i18nKey="dsi_fit_almost_done_view.subtitle"
          t={t}
        />
      </AppointmentDescription>

      <VerticalLayout style={{
        gap: '1.5rem',
      }}
      >
        <ScheduledAppointmentInfo
          address={invitation.clinic.address}
          borderColor={BLUE_CRANIAL_COLOR}
          date={dayjs(invitation.dsiAppointment)}
          duration={t('dsi_fit_almost_done_view.dsi_appointment.duration', {
            duration: '20',
          })}
          title={t('dsi_fit_almost_done_view.dsi_appointment.title')}
        />

        <ScheduledAppointmentInfo
          address={invitation.clinic.address}
          borderColor={GREEN_CRANIAL_COLOR}
          date={dayjs(invitation.fitAppointment)}
          duration={t('dsi_fit_almost_done_view.fit_appointment.duration', {
            minDuration: '50',
            maxDuration: '60',
          })}
          title={t('dsi_fit_almost_done_view.fit_appointment.title')}
        />
      </VerticalLayout>

      <VerticalLayout style={{
        marginTop: '1.5rem',
      }}
      >
        <NextButton
          isLoading={loading}
          onClick={() => {
            postScheduleDSIFitAppointment(invitation, {
              dsiAppointment: invitation.dsiAppointment,
              fitAppointment: invitation.fitAppointment,
            }, setLoading).then(() => {
              navigate('../scheduled', { relative: 'path' })
            }).catch((error) => alertHook.setDialog({
              title: 'Error',
              description: error.message,
            }))
          }}
          text={t('dsi_fit_almost_done_view.button')}
        />
        <BackButton onClick={() => {
          navigate('../fit-appointment', { relative: 'path' })
        }}
        />
      </VerticalLayout>

      <Dialog dialogHook={alertHook} />

    </ViewContainer>
  )
}

const VerticalLayout = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  width: 100%;
`

export default ConfirmScheduledAppointments
