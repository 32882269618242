import { Box, Link } from '@mui/material'
import { Trans } from 'react-i18next'
import { CONTACT_NUMBER } from '../app/constants'
import usePathTranslation from '../hooks/pathTranslation'

const DateOfBirthHelp = () => {
  const { t } = usePathTranslation()
  return (
    <Box
      component="span"
      style={{ fontSize: 12, opacity: 0.6 }}
    >
      <Trans
        components={
      [<Link
        href={`tel:${CONTACT_NUMBER.value}`}
        style={{ whiteSpace: 'nowrap' }}
      />]
    }
        i18nKey="patient_information_form.date_of_birth.subtitle"
        t={t}
        values={{ clinicNumber: CONTACT_NUMBER.description }}
      />
    </Box>
  )
}

export default DateOfBirthHelp
