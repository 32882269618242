import { FormControl, Box, Typography } from '@mui/material'
import styled from '@emotion/styled'
import { IFormSection } from '../app/types'
import FormLabelComponent from './FormLabelComponent'
import { SUB_COLOR } from '../app/constants'

const StyledDescription = styled(Typography)`
  color: ${SUB_COLOR};
  font-size: 12px;
  text-align: left;
  padding-bottom: 10px;
`

const FormSection = ({
  data, title, sub, description,
}: IFormSection) => (
  <Box>
    <FormLabelComponent
      sub={sub}
      title={title}
    />
    <StyledDescription>
      {description}
    </StyledDescription>
    {data.map((item: any) => (
      <FormControl
        key={item.inputComponent.props.name}
        fullWidth
        sx={{ paddingBottom: '10px' }}
      >
        {item.inputComponent}
      </FormControl>
    ))}
  </Box>
)

export default FormSection
