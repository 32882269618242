import { Typography } from '@mui/material'
import styled from '@emotion/styled'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import ViewContainer from '../../../components/ViewContainer'
import AppBar from '../../../components/AppBar'
import usePathTranslation from '../../../hooks/pathTranslation'
import { clearPatientInvitation } from '../../../app/clear-storage'

const SeeYouSoonView = () => {
  const { t } = usePathTranslation()
  const dispatch = useDispatch()
  const invitation = useSelector((state: any) => state.invitation)
  const patientName = invitation.patient.firstName

  useEffect(() => {
    clearPatientInvitation(dispatch)
  }, [])

  return (
    <ViewContainer
      clinicNumberInFooter
      footerDivider
    >
      <AppBar
        description={(
          <Description
            description={t('dsi_fit_see_you_soon_view.subtitle')}
            subDescription={t('dsi_fit_see_you_soon_view.subtitle_2')}
          />
        )}
        maxProgress={5}
        progress={5}
        title={t('dsi_fit_see_you_soon_view.title', {
          guardianName: patientName.charAt(0).toUpperCase() + patientName.slice(1),
        })}
      />
    </ViewContainer>
  )
}

interface DescriptionProps {
  description: string
  subDescription: string
}
const Description = ({ description, subDescription }: DescriptionProps) => (
  <VerticalLayout style={{ gap: '1.5rem' }}>
    <Typography
      sx={{ textAlign: 'left' }}
      variant="subtitle1"
    >
      {description}
    </Typography>

    <Typography
      sx={{ textAlign: 'left' }}
      variant="subtitle1"
    >
      {subDescription}
    </Typography>
  </VerticalLayout>
)

const VerticalLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  height: 100%;
`

export default SeeYouSoonView
