import { Container, Grid, Typography } from '@mui/material'
import styled from '@emotion/styled'
import { DISPLAY_FONT_FAMILY, SUB_COLOR } from '../app/constants'
import { IAppBar } from '../app/types'
import ProgressBar from './ProgressBar'
import LeftArrow from '../assets/leftArrow'
import usePathTranslation from '../hooks/pathTranslation'

const StyledDescription = styled(Typography)`
  color: ${SUB_COLOR};
  font-size: 14px;
`

const StyledContainer = styled(Grid)`
  padding-bottom: 10px;
`

const AppBar = ({
  title,
  description,
  progress,
  maxProgress,
  buttonBackAction,
}: IAppBar) => {
  const { t } = usePathTranslation()
  return (
    <StyledContainer container>
      <Grid
        item
        paddingY={2}
        width="100%"
      >
        {progress !== 0 && (
        <>
          <Container style={{
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
            gap: '10px',
            padding: '0 0 0 0',
          }}
          >
            { buttonBackAction && (
            <button
              aria-label="Go back"
              onClick={buttonBackAction}
              style={{
                border: 'none',
                background: 'none',
                cursor: 'pointer',
                padding: '0',
                margin: '0',
                display: 'inline-flex',
              }}
              type="button"
            >
              <LeftArrow />
            </button>
            )}
            <Typography
              sx={{
                color: SUB_COLOR,
                fontSize: '14px',
                textAlign: 'left',
              }}
            >
              {t('appbar.step_description', {
                step: progress,
                maxStep: maxProgress,
              })}
            </Typography>
          </Container>

          <ProgressBar
            maxProgress={maxProgress}
            progress={progress}
          />
        </>
        )}
        <Typography
          sx={{
            fontFamily: DISPLAY_FONT_FAMILY,
            fontWeight: 600,
            padding: '10px 0',
          }}
          variant="h5"
        >
          {title}
        </Typography>
        <StyledDescription>
          {description}
        </StyledDescription>
      </Grid>
    </StyledContainer>
  )
}

export default AppBar
