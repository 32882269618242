import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import getAvailableAppointmentsSlots from '../api/getAvailableAppointmentsSlots'
import { Invitation } from '../store/invitation'

const useClinicAppointmentSelector = (
  mode: string,
  invitation: Invitation,
  dates: [string | null, string | null],
) => {
  const { clinic: clinicInformation, patient, dsiAppointment } = invitation
  const { email, language, dateOfBirth } = patient
  const [loading, setLoading] = useState(true)
  const [selectedDate, setSelectedDate] = useState<string | null>(null)
  const [selectedTime, setSelectedTime] = useState<string | null>(null)
  const [appointments, setAppointments] = useState<{
    [key: string]: string[];
  }>({})
  const isNextButtonEnabled = !!selectedDate && !!selectedTime
  const availableTimes = selectedDate
    ? appointments[selectedDate as keyof typeof appointments]
    : null
  const availableDateTimes = Object.keys(appointments)

  const handleDateChange = (date: string) => {
    setSelectedDate(date)
    setSelectedTime(null)
  }

  const handleTimeChange = (time: string) => {
    setSelectedTime(time)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const queryParams = new URLSearchParams({
          language,
          service_center_ids: clinicInformation.id.toString(),
          email,
          mode,
        })
        if (mode === 'DSI') {
          if (dates[0]) {
            queryParams.append('start_date', dates[0])
          } if (dates[1]) {
            queryParams.append('end_date', dates[1])
          }
        } else if (mode === 'Fit') {
          const fitDates = calculateFitDates(dayjs(dsiAppointment), dayjs(dateOfBirth))
          queryParams.append('start_date', fitDates[0])
          queryParams.append('end_date', fitDates[1])
        }

        const result = await getAvailableAppointmentsSlots(
          queryParams.toString(),
        )
        const data = result?.data[clinicInformation.id.toString()]
        if (mode === 'DSI') {
          const queryParamsFit = new URLSearchParams({
            language,
            service_center_ids: clinicInformation.id.toString(),
            email,
            mode: 'Fit',
          })
          const fitDates = getFitRange(dates)
          queryParamsFit.append('start_date', fitDates[0])
          queryParamsFit.append('end_date', fitDates[1])
          const fitPossibles = await getAvailableAppointmentsSlots(
            queryParamsFit.toString(),
          )
          removeNotFitAvailables(
            data,
            fitPossibles?.data[clinicInformation.id.toString()],
            dayjs(dateOfBirth),
          )
        }
        const processedData = processAvailableAppointmentSlots(
          data,
        )
        removeSpecificDates(processedData, dayjs(dateOfBirth), mode)
        setAppointments(processedData)
        handleDateChange(Object.keys(processedData)[0])
      } catch (err) {
        setAppointments({})
      } finally {
        setLoading(false)
      }
    }
    setLoading(true)
    fetchData()
  }, [])

  return {
    clinicLocation: clinicInformation,
    selectedDate,
    selectedTime,
    handleDateChange,
    handleTimeChange,
    isNextButtonEnabled,
    availableDates: availableDateTimes,
    availableTimes,
    loading,
  }
}

export type AppointmentSelectorProps = ReturnType<typeof useClinicAppointmentSelector>

export function removeNotFitAvailables(
  dsiData: { [date: string]: string[] },
  fitData: { [date: string]: string[] },
  dateOfBirth: dayjs.Dayjs,

): void {
  const dsiDates = Object.keys(dsiData)
  dsiDates.forEach((date) => {
    const fitsCalculated = calculateFitDates(dayjs(date), dateOfBirth)
    if (!fitData[fitsCalculated[0]] && !fitData[fitsCalculated[1]]) {
      delete dsiData[date]
    }
  })
}

export function removeBlackListedDates(
  data: { [date: string]: string[] },
  blacklistStart: Date,
  blacklistEnd?: Date,
): void {
  const endDate = blacklistEnd ?? blacklistStart
  Object.keys(data).forEach((date) => {
    const currentDate = new Date(date)
    if (currentDate >= blacklistStart && currentDate <= endDate) {
      delete data[date]
    }
  })
}

export function getFitRange(
  dates: [string | null, string | null],
): [string, string] {
  return [
    dayjs(dates[0] ?? undefined).add(7, 'days').format('MM/DD/YYYY'),
    dayjs(dates[1]).add(14, 'days').format('MM/DD/YYYY')]
}

export function calculateFitDates(
  dsiDate: dayjs.Dayjs,
  dateOfBirth: dayjs.Dayjs,
) {
  const monthOldAtDsi = dsiDate.diff(dateOfBirth, 'month')
  if (monthOldAtDsi < 5) {
    return [
      dsiDate.add(7, 'days').format('MM/DD/YYYY'),
      dsiDate.add(8, 'days').format('MM/DD/YYYY'),
    ]
  } if (monthOldAtDsi < 6) {
    return [
      dsiDate.add(9, 'days').format('MM/DD/YYYY'),
      dsiDate.add(10, 'days').format('MM/DD/YYYY'),
    ]
  }
  return [
    dsiDate.add(11, 'days').format('MM/DD/YYYY'),
    dsiDate.add(14, 'days').format('MM/DD/YYYY'),
  ]
}

function processAvailableAppointmentSlots(
  data: { [date: string]: string[] },
) {
  // sort the dates
  const availableDates = Object.keys(data).sort((a, b) => {
    const dateA = dayjs(a, 'MM/DD/YYYY')
    const dateB = dayjs(b, 'MM/DD/YYYY')
    if (dateA.isBefore(dateB)) {
      return -1
    }
    if (dateA.isAfter(dateB)) {
      return 1
    }
    return 0
  })

  const result: { [key: string]: string[] } = {}

  availableDates.forEach((date) => {
    if (data[date].length > 0) {
      result[date] = data[date].map((time) => `${time}:00`)
    }
  })
  return result
}

function removeSpecificDates(
  processedData: { [date: string]: string[] },
  dateOfBirth: dayjs.Dayjs,
  mode: string,
) {
  if (dayjs().diff(dayjs(dateOfBirth), 'month') < 6) {
    if (mode === 'Fit') {
      removeBlackListedDates(
        processedData,
        new Date('2024-12-17'),
        new Date('2025-01-03'),
      )
    } else if (mode === 'DSI') {
      if (dayjs().diff(dayjs(dateOfBirth), 'month') < 5) {
        removeBlackListedDates(
          processedData,
          new Date('2024-12-10'),
          new Date('2024-12-27'),
        )
      } else {
        removeBlackListedDates(
          processedData,
          new Date('2024-12-08'),
          new Date('2025-12-25'),
        )
      }
    }
  }
}

export default useClinicAppointmentSelector
