import dayjs from 'dayjs'
import { useState } from 'react'
import { Controller } from 'react-hook-form'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { es, enUS } from 'date-fns/locale'
import ErrorComponent from './FormErrorComponent'
import PlaceholderComponent from './FormPlaceholderComponent'
import HelperTextComponent from './FormHelperTextComponent'
import { BLUE_CRANIAL_COLOR } from '../app/constants'
import usePathTranslation from '../hooks/pathTranslation'

const FormInputDate = ({
  control,
  errors,
  helperText,
  isLessThan20MonthsOld,
  disablePast,
  name,
  placeholder,
  required,
}: {
  control: any;
  errors: any;
  helperText?: string | JSX.Element;
  isLessThan20MonthsOld?: boolean;
  disablePast?: boolean;
  name: string;
  placeholder: string;
  required?: boolean;
}) => {
  const [isFocus, setIsFocus] = useState(false)
  const [open, setOpen] = useState(false)
  const { language } = usePathTranslation()

  const onKeyDown = (e: any) => {
    e.preventDefault()
  }

  return (
    <LocalizationProvider
      adapterLocale={language === 'en' ? enUS : es}
      dateAdapter={AdapterDateFns}
    >
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value, ref } }) => (
          <DatePicker
            disableFuture={isLessThan20MonthsOld}
            disablePast={disablePast}
            inputRef={ref}
            onChange={onChange}
            onClose={() => setOpen(false)}
            open={open}
            slotProps={{
              openPickerIcon: {
                onClick: () => setOpen(true),
              },
              textField: {
                onKeyDown,
                onClick: () => setOpen(true),
                color: 'info',
                error: !!errors[name],
                helperText:
                  helperText && !errors[name] ? (
                    <HelperTextComponent helperText={helperText} />
                  ) : undefined,
                InputLabelProps: { shrink: false },
                label: !value && !isFocus && (
                  <PlaceholderComponent
                    placeholder={placeholder}
                    required={required}
                  />
                ),
                onBlur: () => setIsFocus(false),
                onFocus: () => setIsFocus(true),
                size: 'small',
              },
              day: {
                sx: {
                  '&.Mui-selected': {
                    backgroundColor: BLUE_CRANIAL_COLOR,
                    color: 'white',
                    ':hover': {
                      backgroundColor: BLUE_CRANIAL_COLOR,
                      color: 'white',
                    },
                    ':focus': {
                      backgroundColor: BLUE_CRANIAL_COLOR,
                      color: 'white',
                    },
                  },
                  '&.Mui-disabled': {
                    color: 'lightgrey',
                    backgroundColor: 'white',
                  },
                  ':hover': {
                    backgroundColor: BLUE_CRANIAL_COLOR,
                    color: 'white',
                  },
                  '&.MuiPickersDay-today': {
                    backgroundColor: 'inherit',
                    ':hover': {
                      backgroundColor: BLUE_CRANIAL_COLOR,
                      color: 'white',
                    },
                  },
                  yearButton: {
                    color: 'red',
                  },
                },
              },
              yearButton: {
                sx: {
                  ':hover': {
                    backgroundColor: BLUE_CRANIAL_COLOR,
                    color: 'white',
                  },
                  '&.Mui-selected': {
                    backgroundColor: BLUE_CRANIAL_COLOR,
                    color: 'white',
                    ':hover': {
                      backgroundColor: BLUE_CRANIAL_COLOR,
                      color: 'white',
                    },
                    ':focus': {
                      backgroundColor: BLUE_CRANIAL_COLOR,
                      color: 'white',
                    },
                  },
                },
              },
            }}
            value={value}
          />
        )}
        rules={{
          required: required && 'This field is required.',
          validate: (value) => {
            if (!dayjs(value).isValid()) return 'Invalid date.'
            if (isLessThan20MonthsOld) {
              const now = dayjs()
              const dateOfBirth = dayjs(value)
              const diff = now.diff(dateOfBirth, 'month')
              if (diff > 20) return 'Patient must be less than 20 months old.'
              if (now.diff(dateOfBirth) < 0) return 'Invalid date.'
            }
            return true
          },
        }}
      />
      {errors[name] && <ErrorComponent message={errors[name].message} />}
    </LocalizationProvider>
  )
}

FormInputDate.defaultProps = {
  isLessThan20MonthsOld: false,
  required: false,
}

export default FormInputDate
