import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { IReschedule, IRescheduleValues } from '../app/types'

export const rescheduleSlicer = createSlice({
  name: 'reschedule',
  initialState: {} as IReschedule,
  reducers: {
    setPatientInfo: (state, action: PayloadAction<IRescheduleValues>) => {
      state.personID = action.payload.personID
      state.patientNumber = action.payload.patientNumber
    },
    setPersonID: (state, action: PayloadAction<number>) => {
      state.personID = action.payload
    },
  },
})

export const { setPatientInfo, setPersonID } = rescheduleSlicer.actions

export default rescheduleSlicer.reducer
